import React, { useRef, useCallback, useState } from "react";
import s from "./Tooltip.module.scss";
import cn from "classnames";
interface IProps {
  content: string;
  delay?: number;
  nowrap?: boolean;
}
const Tooltip: React.FC<IProps> = ({
  children,
  content,
  delay = 500,
  nowrap = false,
}) => {
  const [show, setShow] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [onBottom, setOnBottom] = useState(false);
  const timer = useRef<NodeJS.Timeout>();
  const tooltipRef = useRef<HTMLDivElement>(null);

  const _mouseEnter = useCallback(
    e => {
      const rect = e.target.getBoundingClientRect();
      const { width, left, top, height } = rect;

      if (tooltipRef.current) {
        const { height: tooltipHeight } =
          tooltipRef.current.getBoundingClientRect();

        if (tooltipHeight + 8 > top) {
          setTop(top + height + tooltipHeight);
          setOnBottom(true);
        } else {
          setTop(top);
          setOnBottom(false);
        }
      } else {
        setTop(top);
      }

      setLeft(left + width / 2);
      if (delay === 0) {
        setShow(true);
        return;
      }

      timer.current = setTimeout(() => {
        setShow(true);
      }, delay);
    },
    [setShow, timer.current, setLeft, setTop, delay],
  );

  const _mouseLeave = useCallback(() => {
    setShow(false);
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [setShow, timer.current]);

  const ref = useCallback(node => {
    if (!node || !node.addEventListener) {
      return;
    }
    node.addEventListener("mouseenter", _mouseEnter);
    node.addEventListener("mouseleave", _mouseLeave);
    node.addEventListener("click", _mouseLeave);
    node.addEventListener("wheel", _mouseLeave);
    return () => {
      node.removeEventListener("mouseenter", _mouseEnter);
      node.removeEventListener("mouseleave", _mouseLeave);
      node.removeEventListener("click", _mouseLeave);
      node.removeEventListener("wheel", _mouseLeave);
    };
  }, []);

  return (
    <>
      {show && (
        <div
          style={{
            left: `${left}px`,
            top: `${top}px`,
          }}
          className={cn(s.root, { [s.bottom]: onBottom })}
          ref={tooltipRef}
        >
          <div
            className={cn({
              [s.nowrap]: nowrap,
            })}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}
      {children &&
        React.cloneElement(
          React.Children.only<React.ReactNode[]>(children as React.ReactNode[]),
          {
            ref,
          },
        )}
    </>
  );
};
export default Tooltip;
