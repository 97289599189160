import React, { ImgHTMLAttributes, useEffect, useMemo, useState } from "react";
import { validateURL } from "resources/utils";

const validateSRC = (src?: string) => {
  const regex = new RegExp("^data:image/");
  if (regex.test(src || "")) {
    return src;
  }

  const url = validateURL(src || "");

  if (
    url?.origin === window.location.origin ||
    url?.origin === window.REACT_APP_CARIANS_MEDIA_URL
  ) {
    return url.href;
  }

  return "";
};

const Img = ({
  fallbackSrc,
  src,
  alt,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & { fallbackSrc?: string }) => {
  const [hasError, setHaserror] = useState(false);
  const validateSrc = useMemo(() => {
    return validateSRC(src);
  }, [src]);

  const validateFallbackSrc = useMemo(() => {
    return validateSRC(fallbackSrc);
  }, [fallbackSrc]);

  useEffect(() => {
    setHaserror(false);
  }, [src]);

  if (hasError) {
    return <img {...props} src={validateFallbackSrc} alt={alt} />;
  }

  {
    /* nosemgrep-explanation: use validateSrc to valide src */
  }
  return (
    <img
      {...props}
      src={validateSrc}
      alt={alt}
      onError={() => setHaserror(true)}
    />
  ); // nosemgrep
};

export default Img;
