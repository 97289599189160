import { useEffect, useState } from "react";

const IS_CLIENT = typeof window === "object";

const getScreenSize = (type: string) => {
  let screenWidth, screenHeight: string | number;

  if (IS_CLIENT && type === "string") {
    if (window.innerWidth > 900) {
      screenWidth = "large";
    } else if (window.innerWidth > 320 && window.innerWidth <= 900) {
      screenWidth = "medium";
    } else {
      screenWidth = "small";
    }

    if (window.innerHeight >= 740) {
      screenHeight = "large";
    } else {
      screenHeight = "small";
    }
  } else if (IS_CLIENT && type === "number") {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
  } else {
    type === "string" ? (screenWidth = "") : (screenWidth = 0);
    type === "string" ? (screenHeight = "") : (screenHeight = 0);
  }

  return [screenWidth, screenHeight];
};

export default function useWindowSize(
  type: "string" | "number",
): [string | number, string | number] {
  const [windowWidth, setWindowWidth] = useState(getScreenSize(type)[0]);
  const [windowHeight, setWindowHeight] = useState(getScreenSize(type)[1]);

  useEffect(() => {
    if (!IS_CLIENT) {
      return;
    }

    const handleResize = () => {
      setWindowWidth(getScreenSize(type)[0]);
      setWindowHeight(getScreenSize(type)[1]);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [type]);

  return [windowWidth, windowHeight];
}
