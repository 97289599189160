import Octopus from "../Octopus";
import { GetExpertInfosResult } from "./experts-types";
import { useQuery } from "react-query";
import { postLogs } from "api/logs";
import { Feedback } from "store/app/types";

export const getExpertInfo = (id: string): Promise<GetExpertInfosResult> => {
  return Octopus.request("get", "/ex/experts/info", undefined, { id })
    .then(async res => ({
      disease: res.disease,
      info: res.info,
      place: res.place,
      responsability: res.responsability,
    }))
    .catch(err => err);
};

export async function fetchExpertInfos(
  id: string,
): Promise<GetExpertInfosResult> {
  const res = await Octopus.request("get", "/ex/experts/info", undefined, {
    id,
  });
  const { disease, info, place, responsability } = res;
  return {
    disease,
    info,
    place,
    responsability,
  };
}

export const useExpertInfos = (id: string) =>
  useQuery([`expertInfos-${id}`, id], () => fetchExpertInfos(id), {
    staleTime: 600000,
    cacheTime: 600000 /* 10mins */,
    enabled: !!id,
    retry: false,
  });

async function fetchExpertDiseases(): Promise<{ id: number; name: string }[]> {
  const res = await Octopus.request("get", "/ex/experts/diseases");
  return res.result;
}

export const useExpertDiseases = () =>
  useQuery([`expertDieases`], fetchExpertDiseases, {
    staleTime: 600000,
    cacheTime: 600000 /* 10mins */,
    retry: false,
  });

export const getExpertInvoices = () => {
  return Octopus.request("get", "/ex/experts/invoices")
    .then(async res => (res && res.result ? res.result : []))
    .catch(err => err);
};

export const getExpertInvoiceDocument = (
  request_id: string,
  invoice_id: string,
) => {
  return Octopus.request("get", "/ex/experts/invoices/document", undefined, {
    request_id,
    invoice_id,
  })
    .then(async res => (res && res.result ? res.result : null))
    .catch(err => err);
};

export const getDownloadAnnualReport = (year: string) =>
  Octopus.request("GET", "/ex/experts/invoices/download", undefined, {
    year,
  });

export interface ExpertInvoiceAnnualReport {
  expert: ExpertInvoiceAnnualReportExpert;
  invoices: ExpertInvoiceAnnualReportInvoice[];
}

export interface ExpertInvoiceAnnualReportExpert {
  address: string;
  city: string;
  country: string;
  first_name: string;
  last_name: string;
  rpps: string;
  specialty: string;
  title: string;
  urssaf_number: string;
  zip_code: string;
}

export interface ExpertInvoiceAnnualReportInvoice {
  expert_number: string;
  primary_opinion_date: string;
  request_id: string;
}

export const getExpertInvoiceAnnualReport = (year: string) => {
  return Octopus.request(
    "get",
    "/ex/experts/invoices/annual-report",
    undefined,
    {
      year,
    },
  );
};

export const postExpertInvoiceDocument = (
  request_id: string,
  invoice_id: string,
  file_name: string,
  file_content: string,
) => {
  return Octopus.request(
    "post",
    "/ex/experts/invoices/document",
    { invoice_id, file_name, file_content },
    { request_id },
  )
    .then(async res => (res && res.result ? res.result : null))
    .catch(err => {
      postLogs({
        request_id,
        type: "old-logs",
        msg: JSON.stringify(err),
        req_path: window.location.pathname,
      });
      return err;
    });
};
export interface IGetExpertInvoiceInfo {
  address: string;
  amount: string;
  city: string;
  code_number: string;
  country: string;
  creation_date: string;
  expert_number: string;
  first_name: string;
  health_insurance: string;
  last_name: string;
  payment_date: string;
  payment_type: string;
  primary_opinion_date: string;
  request_id: string;
  specialty: string;
  title: string;
  urssaf_number: string;
  validation_date: string;
  zip_code: string;
}

export const getExpertInvoiceInfo = async (
  request_id: string,
  invoice_id: string,
): Promise<IGetExpertInvoiceInfo[]> => {
  const res = await Octopus.request(
    "get",
    "/ex/experts/invoices/info",
    undefined,
    {
      request_id,
      invoice_id,
    },
  );
  return await res.result;
};

export const postExpertDocument = (
  document_id: string,
  file_name: string,
  file_content: string,
) => {
  return Octopus.request("post", "/ex/experts/document", {
    document_id,
    file_name,
    file_content,
  })
    .then(res => res)
    .catch(err => {
      postLogs({
        type: "old-logs",
        msg: JSON.stringify(err),
        req_path: window.location.pathname,
      });
      return err;
    });
};

export const patchExpertsEvaluation = async (
  request_id: string,
  data: { comment: string },
) => {
  try {
    const res = await Octopus.request(
      "patch",
      "/ex/experts/evaluation",
      {
        ...data,
      },
      { request_id },
    );
    return res;
  } catch (err) {
    postLogs({
      request_id,
      type: "old-logs",
      msg: JSON.stringify(err),
      req_path: window.location.pathname,
    });
    return err;
  }
};

export const putExpertsEvaluation = async (
  request_id: string,
  data: Record<string, string>,
) => {
  try {
    const res = await Octopus.request(
      "put",
      "/ex/experts/evaluation",
      {
        ...data,
      },
      { request_id },
    );
    return res;
  } catch (err) {
    try {
      postLogs({
        request_id,
        type: "old-logs",
        msg: JSON.stringify(err),
        req_path: window.location.pathname,
      });
    } catch (e) {
      console.error(e);
    }
    return err;
  }
};

export const getRequestsEvaluationDeuxiemeavis = async (
  request_id: string,
): Promise<Feedback> => {
  try {
    const res = await Octopus.request(
      "get",
      "/ex/requests/evaluation/deuxiemeavis",
      {},
      { request_id },
    );
    return { ...res.medical, ...res["non-medical"] };
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const putRequestsEvaluationDeuxiemeavisSetAsRead = async (
  request_id: string,
): Promise<{ success: boolean }> => {
  try {
    const res = await Octopus.request(
      "put",
      "/ex/requests/evaluation/deuxiemeavis/set-as-read",
      {},
      { request_id },
    );

    if (res.result === "ok") {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    console.error(err);
    return { success: false };
  }
};

export const patchExpertSummaryOfSkills = (summary_of_skills: string) => {
  return Octopus.request("put", "/ex/experts/summary-of-skills", {
    summary_of_skills,
  })
    .then(res => res)
    .catch(err => err);
};
