import React from "react";
import s from "./Disclosure.module.scss";
import { Transition, Disclosure } from "@headlessui/react";
import cn from "classnames";
import { Text, ChevronTopIcon, ChevronDownIcon } from "@carians/carians-ui";
interface DisclosureMenu {
  icon: JSX.Element;
  label: string;
  defaultOpen?: boolean;
}
const DisclosureMenu: React.FC<DisclosureMenu> = ({
  icon,
  label,
  children,
  defaultOpen,
}) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Text
            variant="body-medium"
            color="darkblue.500"
            display="flex"
            gap="8px"
            p="12px 16px"
            as={Disclosure.Button}
            className={cn(s.disclosure_btn, { [s.open]: open })}
          >
            {icon}
            <div>{label}</div>
            {open ? <ChevronTopIcon /> : <ChevronDownIcon />}
          </Text>
          <Transition
            className={s.disclosure}
            enter={s.enter}
            enterFrom={s.enterFrom}
            enterTo={s.enterTo}
            leave={s.leave}
            leaveFrom={s.leaveFrom}
            leaveTo={s.leaveTo}
          >
            <Disclosure.Panel
              className={cn(s.closure_panel, { [s.open]: open })}
              as="ul"
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
export default DisclosureMenu;
