export interface IMessageState {
  message: string;
  file_name: string;
  attachment: string;
  creation_date: string;
  post_id: string;
  author_id: string;
  author_table: string;
  first_name: string;
  last_name: string;
  is_read: string;
  show_as_read: "0" | "1";
}



export interface Feedback {
  accept_testimony: "0" | "1";
  better_informed: string;
  more_reassured: string;
  no_recommandation_explanation: string;
  opinion_satisfaction: string;
  patient_support_contact: "0" | "1";
  patient_support_rate: string;
  ready_make_decision: string;
  recommend_service: "0" | "1";
  regret_no_alternative: string;
  regret_no_amelioration: "0" | "1";
  request_id: string;
  satisfaction_rate: "-2" | "-1" | "1" | "2";
  situation_resume: string;
  understand_better: "0" | "1";
  dissatisfaction_explanation: string;
  is_read: "0" | "1";
}
export interface AppState {
  messageModalOpen: boolean;
  messages?: IMessageState[];
  feedback?: Feedback;
}

export const SET_MESSAGE_MODAL_OPEN = "SET_MESSAGE_MODAL_OPEN";
export const GET_MESSAGES = "GET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";

interface SetMessageModalOpen {
  type: typeof SET_MESSAGE_MODAL_OPEN;
  payload: boolean;
}

interface GetMessagesActions {
  type: typeof GET_MESSAGES;
  payload: AppState["messages"];
}

interface ClearMessagesActions {
  type: typeof CLEAR_MESSAGES;
}

interface GetFeedbackAction {
  type: typeof GET_FEEDBACK;
  payload: AppState["feedback"];
}

interface ClearFeedbackActions {
  type: typeof CLEAR_FEEDBACK;
}

export type AppActionTypes =
  | SetMessageModalOpen
  | GetMessagesActions
  | ClearMessagesActions
  | GetFeedbackAction
  | ClearFeedbackActions;
