import React, { useMemo } from "react";
import { displayPhone } from "resources/utils";
import { TFunction, useTranslation } from "react-i18next";
import { differenceInYears } from "date-fns";
import { GenderOption, useGenderList } from "api/global";
import { useSelector } from "react-redux";
import { Text, Heading, LanguageIcon } from "@carians/carians-ui";

function langCodeToLanguage(lang: string): string {
  return lang === "fr" ? "french" : "english";
}

export function getSubTitle(
  title: string,
  isPatient: string,
  patient_relation: string,
  birthdate: string,
  gender_id: string,
  genderList: GenderOption[],
  t: TFunction<"request", undefined>,
) {
  if (title === "demandeur" && isPatient !== "1") {
    return t(patient_relation);
  }
  if (!birthdate) return;
  const age = differenceInYears(new Date(), new Date(birthdate));
  let gender;

  for (let i = 0; i < genderList.length; i++) {
    if (genderList[i].id === gender_id) {
      gender = genderList[i].name;
      break;
    }
  }

  return `${gender} |  ${t("birth", {
    gender: gender_id === "526" ? "e" : gender_id === "525" ? "" : "/e",
  })} ${birthdate.split("-").reverse().join("/")} (${age} ${
    Number(age) > 1 ? t("agePlur") : t("age")
  })`;
}

interface InfoElementProps {
  title: string;
  first_name: string;
  last_name: string;
  birthdate: string;
  gender_id: string;
  street_address: string;
  zip_code: string;
  city: string;
  country: string;
  email: string;
  telephone: string;
  phone_code: string;
  is_patient: string;
  patient_relation: string;
}

const InfoElement: React.FC<InfoElementProps> = ({
  title,
  first_name,
  last_name,
  birthdate,
  gender_id,
  street_address,
  zip_code,
  city,
  country,
  email,
  telephone,
  phone_code,
  is_patient,
  patient_relation,
}) => {
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const { data: genderList } = useGenderList(lang);
  const { t } = useTranslation("request");

  const subTitle = useMemo(() => {
    if (genderList) {
      return getSubTitle(
        title,
        is_patient,
        patient_relation,
        birthdate,
        gender_id,
        genderList?.options,
        t,
      );
    } else {
      return undefined;
    }
  }, [genderList, title, is_patient, patient_relation, birthdate, gender_id]);

  return (
    <div className="info-element">
      <Text
        as="div"
        variant="body-bold"
        color="primary.500"
        textTransform="uppercase"
        mb="8px"
      >
        {t(title)}
      </Text>
      <Heading as="div" variant="h1-bold" color="darkblue.500">
        {first_name} {last_name}
      </Heading>
      <Text as="div" variant="body-bold" color="darkblue.500">
        {typeof subTitle === "string" ? `${subTitle}` : ""}
      </Text>
      {street_address &&
        (is_patient === "1" ||
          (is_patient !== "1" && title === "demandeur")) && (
          <Text as="div" variant="body" color="darkblue.500">
            {street_address}, {zip_code}, {city}, {country}
          </Text>
        )}
      {lang &&
        (is_patient === "1" ||
          (is_patient !== "1" && title === "demandeur")) && (
          <Text as="div" className="lang" variant="body-bold">
            <LanguageIcon mr="16px" />
            {t(langCodeToLanguage(lang))}
          </Text>
        )}
      {email &&
        (is_patient === "1" ||
          (is_patient !== "1" && title === "demandeur")) && (
          <Text as="div" variant="body-bold" color="darkblue.300" mt="13px">
            {email}
          </Text>
        )}
      {telephone &&
        (is_patient === "1" ||
          (is_patient !== "1" && title === "demandeur")) && (
          <Text as="div" variant="body-bold" color="darkblue.300">
            {displayPhone(phone_code, telephone)}
          </Text>
        )}
    </div>
  );
};

export default InfoElement;
