import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import style from "./Question.module.scss";
import cn from "classnames";
import { SaveIcon } from "@carians/carians-ui";

interface SaveProps {
  show: boolean;
  sub?: boolean;
}

const Save = ({ show, sub }: SaveProps) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={cn(style.saved, { [style.sub]: sub })}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 8, opacity: 0 }}
        >
          <SaveIcon size="xs" />
          <span className={style.text}>Enregistré</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Save;
