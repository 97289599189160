import React, { useState, useEffect, ChangeEvent } from "react";

import "./style.scss";
import Input from "components/Input";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { TemplateOpinionEnValues } from "./en/TemplateOpinionEn";
import { TemplateOpinionFrValues } from "./fr/TemplateOpinionFr";
import { TFunction } from "react-i18next";
import { useDocument } from "contexts/DocumentContext";
import { DocumentsState } from "store/documents/types";
import { sanitize } from "dompurify";
import i18n from "i18n";
import { Text } from "@carians/carians-ui";

interface IPropsSignature<T> {
  type: "quotate" | "opinion" | "inter";
  saveOpinion: (values: T) => Promise<void>;
  values: T;
  t: TFunction;
}

const Signature = <
  T extends TemplateOpinionEnValues | TemplateOpinionFrValues,
>({
  type,
  saveOpinion,
  values,
  t,
}: IPropsSignature<T>) => {
  const tFr = i18n.getFixedT("fr", "document");
  const [value, setValue] = useState("");
  const [defaultValue] = useState(values.signature);
  const { state } = useDocument();
  const hiddenError = useSelector(
    (state: { documents: DocumentsState }) => state.documents.hiddenError,
  );
  const { short_title, last_name, first_name } = useSelector(
    (state: StoreState) => state.session.user,
  );

  useEffect(() => {
    if (values?.signature !== value) {
      setValue(values?.signature || value);
    }
  }, [value, values.signature]);

  const saveSignature = (value: string) => {
    if (defaultValue !== value) {
      saveOpinion({ ...values, signature: value });
    }
  };

  return (
    <div className="signature" data-hj-suppress>
      {!state?.evaluationMode && (
        <Field
          name={`signature`}
          component={Input}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            e.target && setValue(e.target.value)
          }
          onBlur={(e: ChangeEvent<HTMLInputElement>) =>
            e.target && saveSignature(e.target.value)
          }
          placeholder={t(`template.${type}.signature.placeholder`)}
          showError={!hiddenError}
        />
      )}
      <div className="result">
        {!state?.evaluationMode && (
          <Text as="div" variant="small-semi-bold">
            {t(`template.${type}.signature.label`)}
          </Text>
        )}
        {state?.evaluationMode && (
          <Text as="div" variant="small-semi-bold">
            {short_title} {first_name} {last_name}
          </Text>
        )}
        <div className="value">{value}</div>
      </div>
      {!state?.evaluationMode && (
        <Text
          as="div"
          mt="25px"
          variant="body-semi-bold"
          color="darkblue.500"
          dangerouslySetInnerHTML={{
            __html: sanitize(tFr(`template.${type}.signature.info`)),
          }}
        />
      )}
    </div>
  );
};

export default Signature;
