import React from "react";
import "./Divider.scss";

interface DividerProps {
  mobile?: boolean;
  desktop?: boolean;
  bar?: boolean;
  small?: boolean;
}

export default function Divider({ mobile, desktop, bar, small }: DividerProps) {
  let className = "divider";

  mobile ? (className = className + " mobile") : null;
  desktop ? (className = className + " desktop") : null;
  bar ? (className = className + " bar") : null;
  small ? (className = className + " small") : null;
  return <div className={className}>{bar && <div className="bar" />}</div>;
}
