import React from "react";
import style from "./Title.module.scss";
import { useParams } from "react-router";
import { TFunction } from "react-i18next";
import { usePatientInfos } from "api/request";
import { Logo, Heading, Text } from "@carians/carians-ui";

interface IPropsResum {
  type: string;
  t: TFunction;
}

const Title = ({ type, t }: IPropsResum) => {
  const { request_id } = useParams<{ request_id: string }>();
  const { data: infoPatient } = usePatientInfos(request_id);

  return (
    <div className={style.root}>
      <div className={style.informations}>
        <Heading as="div" variant="h1-bold">
          {t(`template.${type}.proofreading.title`)}
        </Heading>
        <Text className={style.date}>
          {t(`template.${type}.proofreading.date`, {
            date: t("app:formattedDate", { date: new Date() }),
          })}
        </Text>
        <Text as="div" className={style.infoPatient}>
          {t(`template.${type}.proofreading.patient.intro`)}
          <span className={style.name} data-hj-suppress>{`${t(
            `template.gender.${infoPatient?.gender_id}`,
          )} ${infoPatient?.last_name} ${infoPatient?.first_name}`}</span>
          <span className={style.date}>
            {t(
              `template.${type}.proofreading.patient.birthdate_${
                infoPatient?.gender_id === "526" ? "2" : "1"
              }`,
              {
                birthdate: infoPatient?.birthdate
                  .split("-")
                  .reverse()
                  .join("/"),
              },
            )}
          </span>
        </Text>
      </div>
      <Logo mini width={35} />
    </div>
  );
};

export default Title;
