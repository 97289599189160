import React, { useMemo } from "react";
import cn from "classnames";
import s from "./ModalOnboarding.module.scss";
import { useTranslation } from "react-i18next";
import { Button, IconButton, CrossIcon, CheckIcon } from "@carians/carians-ui";

interface ModalBottomProps {
  title: string;
  message: string;
  onNext: () => void;
  onClose: () => void;
  last: boolean;
  focus: (n: number) => () => void;
  nbSteps: number;
  current: number;
}

const ModalBottom = React.forwardRef(
  (
    {
      title,
      message,
      last,
      onNext,
      onClose,
      focus,
      nbSteps,
      current,
    }: ModalBottomProps,
    ref?: React.LegacyRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation("introduction");
    const arrayDots = useMemo(() => {
      return Array.from({ length: nbSteps }, (_, k) => k);
    }, [nbSteps]);
    return (
      <div ref={ref} className={s.root}>
        <div className={s.container}>
          <IconButton
            aria-label="Fermer la modal"
            onClick={onClose}
            className={s.close}
            variant="flat"
            icon={<CrossIcon />}
          />

          <div className={s.body}>
            <div className={s.title}>{title}</div>
            <div className={s.message}>
              {message.split("\n").map((x, i) => (
                <div key={i}>{x}</div>
              ))}
            </div>
          </div>
          <div className={s.btn}>
            <div className={s.dots}>
              {arrayDots.map(i => (
                <button
                  key={i}
                  onClick={focus(i + 1)}
                  type="button"
                  className={cn(s.dot, { [s.active]: current === i + 1 })}
                />
              ))}
            </div>
            {last ? (
              <Button onClick={onClose} leftIcon={<CheckIcon />}>
                {t("button.finish")}
              </Button>
            ) : (
              <Button onClick={onNext}>{t("button.next")}</Button>
            )}
          </div>
        </div>
      </div>
    );
  },
);
export default ModalBottom;
