import Img from "components/Img";
import React from "react";
import { useTranslation } from "react-i18next";
import s from "./ModalOnboarding.module.scss";
import { Button } from "@carians/carians-ui";

interface ModalCenterProps {
  img: string;
  title: string;
  content: string;
  onClose: () => void;
  onNext: () => void;
}

const ModalCenter: React.FC<ModalCenterProps> = ({
  img,
  title,
  content,
  onClose,
  onNext,
}) => {
  const { t } = useTranslation("introduction");
  return (
    <div className={s.modal}>
      <div className={s.img}>
        <Img src={img} alt="modal" />
      </div>
      <div className={s.body}>
        <div className={s.title}>{title}</div>
        <div className={s.content}>
          {content.split("\n").map((x, index) => (
            <div key={index}>{x}</div>
          ))}
        </div>
        <div className={s.btns}>
          <Button onClick={onClose} variant="flat">
            {t("button.skip")}
          </Button>
          <Button onClick={onNext}>{t("button.start")}</Button>
        </div>
      </div>
    </div>
  );
};
export default ModalCenter;
