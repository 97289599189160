import { CancelToken } from "axios";
import Octopus from "./Octopus";

interface Messages {
  message: string;
  recipient_table: string;
  recipient_id: string;
  file_name: string;
  file_content: string;
  creation_date: string;
  attachment: string;
  first_name: string;
  last_name: string;
  author_id: string;
  author_table: string;
  is_read: "0" | "1";
  show_as_read: "0" | "1";
  post_id: string;
}

export async function ImportMessages(
  request_id: string,
  type: string,
): Promise<{
  result: Messages[];
  message: { [key: string]: { message: string; file_name?: string } };
}> {
  return await Octopus.request(
    "get",
    type === "chat" ? "/ex/chat" : "/ex/messages",
    undefined,
    {
      request_id,
      type,
    },
  );
}

export async function SendMessage(
  request_id: string,
  message: string,
  type: string,
  recipient_table: string,
  recipient_id: string,
  file?: {
    file_content: string;
    file_name: string;
  },
) {
  return await Octopus.request(
    "post",
    type === "chat" ? "/ex/chat" : "/ex/messages",
    {
      message,
      type,
      recipient_table,
      recipient_id,
      file_name: file?.file_name,
      file_content: file?.file_content,
    },
    {
      request_id,
    },
  );
}
export interface RequestsFileInfo {
  "content-type": string;
  content: string;
}

export async function GetFileInfo(
  request_id: string,
  path: string,
  cancelToken?: CancelToken,
): Promise<ArrayBuffer | RequestsFileInfo> {
  const res = await Octopus.request(
    "get",
    "/ex/requests/file",
    undefined,
    {
      request_id,
      path,
    },
    path === "/medical" ? "arraybuffer" : "json",
    cancelToken,
  );
  return res?.result || res;
}

export async function setMessageAsRead(request_id: string, type: string) {
  return await Octopus.request(
    "put",
    type === "chat" ? "ex/chat/set-as-read" : "ex/messages/set-as-read",
    {
      type,
    },
    {
      request_id,
    },
  );
}

export const setMessageShowAsRead = async ({
  request_id,
  show_as_read,
}: {
  request_id: string;
  show_as_read: boolean;
}) => {
  return await Octopus.request(
    "put",
    "ex/messages/set-show-as-read",
    {
      show_as_read: Number(show_as_read),
    },
    {
      request_id,
    },
  );
};
