import React, { useState, useCallback } from "react";
import { FAQPage, getFaqPage } from "api/help";
import {
  HelpCenter,
  HelpIcon,
  Button,
  useMediaQuery,
  IconButton,
} from "@carians/carians-ui";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { getPageCode } from "routes/Help/utils";

const HelpButton = () => {
  const [faqs, setFaqs] = useState<FAQPage[] | null>(null);
  const { t } = useTranslation("header");
  const location = useLocation();
  const { push } = useHistory();
  const { request_id } = useParams<{ request_id: string }>();
  const [isMobile] = useMediaQuery("(max-width: 900px)");

  const fetch = useCallback(async () => {
    const code = await getPageCode(location.pathname);
    const { result } = await getFaqPage(code);

    if (result) {
      setFaqs(result);
    } else {
      setFaqs([]);
    }
  }, [location]);

  return (
    <>
      {isMobile ? (
        <IconButton
          icon={<HelpIcon />}
          aria-label={t("help.button")}
          onClick={() => fetch()}
          variant="flat"
          colorScheme="darkblue"
        />
      ) : (
        <Button
          leftIcon={<HelpIcon />}
          onClick={() => fetch()}
          variant="flat"
          colorScheme="darkblue"
          size="sm"
        >
          {t("help.button")}
        </Button>
      )}

      <HelpCenter
        title={t("help.title")}
        subTitle=""
        button={t("help.button")}
        moreButton={{
          text: t("help.moreButton"),
          onClick: () =>
            push({
              pathname: "/help",
              state: { from: location.pathname, request_id },
            }),
        }}
        faqs={faqs || []}
        isOpen={!!faqs}
        onClose={() => setFaqs(null)}
        footer={{
          title: t("help.footer.title"),
          text: t("help.footer.text"),
        }}
      />
    </>
  );
};

export default HelpButton;
