import apiError from "./apiError.json";
import app from "./app.json";
import pageNotFound from "./pageNotFound.json";
import login from "./login.json";
import form from "./form.json";
import forgottenPassword from "./forgottenPassword.json";
import resetPassword from "./resetPassword.json";
import header from "./header.json";
import footer from "./footer.json";
import otp from "./otp.json";
import profile from "./profile.json";
import expertProfile from "./expertProfile.json";
import menu from "./menu.json";
import metrics from "./metrics.json";
import requests from "./requests.json";
import request from "./request.json";
import document from "./document.json";
import fileViewer from "./fileViewer.json";
import chat from "./chat.json";
import help from "./help.json";
import evaluation from "./evaluation.json";
import compatibilityMessage from "./compatibilityMessage.json";
import dashboard from "./dashboard.json";
import onboarding from "./onboarding.json";
import cookies from "./cookies.json";
import introduction from "./introduction.json";
import error from "./error.json";
import feedback from "./feedback.json";
export default {
  apiError,
  app,
  login,
  form,
  forgottenPassword,
  resetPassword,
  pageNotFound,
  footer,
  header,
  otp,
  profile,
  expertProfile,
  menu,
  requests,
  request,
  document,
  fileViewer,
  chat,
  help,
  evaluation,
  compatibilityMessage,
  metrics,
  dashboard,
  onboarding,
  cookies,
  introduction,
  error,
  feedback,
};
