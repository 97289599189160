import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMedia } from "react-use";
import { mapGet } from "resources/utils";
import { OnboardingContext, OnboardingStep } from "./context";
import { ModalBottom } from "./Modal";
import ModalCenter from "./Modal/ModalCenter";
import s from "./Onboarding.module.scss";

interface OnboardingProps {
  start?: boolean;
  initSteps?: Record<number, OnboardingStep>;
  nbSteps?: number;
  onClose?: () => void;
  modalStart: {
    title: string;
    content: string;
    img: string;
  };
}

const Onboarding: React.FC<OnboardingProps> = ({
  start = false,
  initSteps = {},
  nbSteps,
  children,
  onClose,
  modalStart,
}) => {
  const [current, setCurrent] = useState(0);
  const [isStart, setIsStart] = useState(start);
  const [steps, setSteps] = useState<Record<number, OnboardingStep>>(initSteps);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMedia("(min-width: 900px)");

  useEffect(() => {
    setIsStart(start);
  }, [start]);

  const nSteps = useMemo(() => {
    return Object.keys(steps).length;
  }, [steps]);

  const next = useCallback(() => {
    setCurrent(prev => prev + 1);
  }, [setCurrent]);

  const prev = useCallback(() => {
    setCurrent(prev => prev - 1);
  }, [setCurrent]);

  const on = useCallback(() => {
    setIsStart(true);
  }, [setIsStart]);
  const off = useCallback(() => {
    setIsStart(false);
    if (onClose) {
      onClose();
    }
  }, [setIsStart, onClose]);

  const registerStep = useCallback(
    (key: number, entry: OnboardingStep) => {
      setSteps(prev => ({ ...prev, [key]: entry }));
    },
    [setSteps],
  );

  const currentItem = useMemo(() => {
    return mapGet(steps, current);
  }, [steps, current]);

  const handler = useCallback(() => {
    if (!currentItem || currentItem.modalOnly) {
      setRect(null);
      return;
    }
    setRect(currentItem.getRect());
  }, [setRect, currentItem]);

  useEffect(() => {
    setTimeout(() => {
      handler();
    }, 50);
    handler();
    window.addEventListener("resize", handler);
    window.addEventListener("scroll", handler);
    return () => {
      window.addEventListener("resize", handler);
      window.removeEventListener("scroll", handler);
    };
  }, [currentItem]);

  const canStart = useMemo(() => {
    return isStart && nbSteps === nSteps;
  }, [isStart, nbSteps, nSteps]);

  const styleHighlighting = useMemo(() => {
    if (!rect) {
      return {
        height: 0,
        left: window.innerWidth / 2,
        top: window.innerHeight / 2,
        width: 0,
      };
    }
    if (currentItem?.paddingValues) {
      const [top, right, bottom, left] = currentItem.paddingValues;
      return {
        height: rect.height + top + bottom,
        left: rect.left - left,
        top: rect.top - top,
        width: rect.width + right + left,
      };
    }
    return {
      height: rect.height,
      left: rect.left,
      top: rect.top,
      width: rect.width,
    };
  }, [rect, currentItem]);

  const focusCurrent = useCallback(
    (id: number) => () => {
      setCurrent(id);
    },
    [setCurrent],
  );

  if (!isDesktop) {
    return <>{children}</>;
  }

  return (
    <OnboardingContext.Provider
      value={{ current, isStart, next, prev, on, off, registerStep, modalRef }}
    >
      {canStart && (
        <>
          <div className={s.overlay} />
          <div className={s.highlighting} style={styleHighlighting}></div>
          {current === 0 ? (
            <ModalCenter
              onNext={next}
              onClose={off}
              img={modalStart.img}
              title={modalStart.title}
              content={modalStart.content}
            />
          ) : (
            <ModalBottom
              ref={modalRef}
              nbSteps={nbSteps || 0}
              focus={focusCurrent}
              title={currentItem?.title || ""}
              message={currentItem?.message || ""}
              onNext={next}
              onClose={off}
              last={current === nSteps}
              current={current}
            />
          )}
        </>
      )}
      {children}
    </OnboardingContext.Provider>
  );
};
export default Onboarding;
