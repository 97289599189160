import {
  SET_SCREEN_SIZE,
  PUSH_MENU,
  CLOSE_MENU,
  SHOW_COOKIE_POPIN,
  HIDE_COOKIE_POPIN,
} from "./types";
import { ReactText } from "react";

export const showCookiePopin = () => {
  return {
    type: SHOW_COOKIE_POPIN,
  };
};
export const hideCookiePopin = () => {
  return {
    type: HIDE_COOKIE_POPIN,
  };
};

export function setScreenSize(
  size: [string | number | ReactText, string | number | ReactText],
) {
  return {
    type: SET_SCREEN_SIZE,
    payload: size,
  };
}

export function pushMenu() {
  return {
    type: PUSH_MENU,
  };
}

export function closeMenu() {
  return {
    type: CLOSE_MENU,
  };
}
