const ACCENTS =
  "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž" as const;
const ACCENTS_OUT =
  "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz" as const;

const removeAccents = (text: string) => {
  return text
    .split("")
    .map(letter => {
      const accentIndex = ACCENTS.indexOf(letter);
      return accentIndex !== -1 ? ACCENTS_OUT[accentIndex] : letter;
    })
    .join("");
};
export default removeAccents;
