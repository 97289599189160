import React, { useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TemplateOpinionFr from "routes/Documents/EditDocument/Template/fr/TemplateOpinionFr";
import TemplateOpinionEn from "routes/Documents/EditDocument/Template/en/TemplateOpinionEn";
import Loading from "components/Loading";
import { DocumentsState } from "store/documents/types";
import {
  DocumentContext,
  reducer,
  initialState,
} from "contexts/DocumentContext";

const Overview = () => {
  const { t } = useTranslation("document");

  const opinion = useSelector(
    (state: { documents: DocumentsState }) => state.documents.opinion,
  );
  const currentDoc = useSelector(
    (state: { documents: DocumentsState }) => state.documents.currentDoc,
  );

  const type = useMemo(() => {
    if (!opinion || !currentDoc) {
      return "opinion";
    }

    switch (currentDoc.document_type_id) {
      case "531":
        return "opinion";
      case "665":
        return "inter";
      case "666":
        return "quotate";
      default:
        return "opinion";
    }
  }, [opinion]);

  const [state] = useReducer(reducer, {
    ...initialState,
    evaluationMode: true,
  });

  if (!opinion || !currentDoc) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <DocumentContext.Provider value={{ state }}>
      {type === "opinion" ? (
        <TemplateOpinionFr t={t} />
      ) : (
        <TemplateOpinionEn t={t} />
      )}
    </DocumentContext.Provider>
  );
};

export default Overview;
