import axios, {
  Method,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from "axios";
import { sanitize } from "dompurify";
import { mapGet, mapSet } from "resources/utils";

export default class Octopus {
  static async request(
    method: Method,
    url: string,
    data?: Record<string, unknown>,
    params?: Record<string, unknown>,
    returnType: AxiosRequestConfig["responseType"] = "json",
    cancelToken?: CancelToken,
  ) {
    const baseURL = window.REACT_APP_CARIANS_API_URL;

    function cleanData(data: Record<string, unknown>) {
      const clean: Record<string, unknown> = {};

      // Recursively parse the config.data object in order to sanitize all string but only strings
      function purge<T>(data: T) {
        for (const key in data) {
          const item = mapGet(data, key);

          if (Array.isArray(item)) {
            if (item.length > 0) {
              const value = item.map(item => {
                if (typeof item === "string") {
                  return sanitize(item);
                } else if (Array.isArray(item)) {
                  return purge(item);
                } else if (typeof item === "object") {
                  return purge(item);
                } else {
                  return item;
                }
              });
              mapSet(clean, key, value);
            } else {
              mapSet(clean, key, item);
            }
          } else if (typeof item === "object") {
            mapSet(clean, key, purge(item));
          } else if (typeof item === "string") {
            mapSet(clean, key, sanitize(item));
          } else {
            mapSet(clean, key, item);
          }
        }
      }
      purge(data);
      return clean;
    }

    const config: AxiosRequestConfig = {
      responseType: returnType,
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: `${baseURL}/v2/deuxiemeavis`,
      data: data ? cleanData(data) : data,
      params,
      cancelToken,
    };

    try {
      const response: AxiosResponse = await axios(config);

      if (!response) return null;

      if (response.headers["content-type"] === "application/octet-stream") {
        const buffer = Buffer.from(response.data, "binary").toString("base64");
        return {
          content: buffer,
          "content-type": response.headers["content-type"],
        };
      }
      if (response.data) {
        return response.data;
      } else return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      } else {
        if (
          error.response.data &&
          error.response.data.hasOwnProperty("Error")
        ) {
          throw error.response.data;
        }
        if (
          error.response.data &&
          error.response.data.hasOwnProperty("result") &&
          error.response.data.hasOwnProperty("error")
        ) {
          throw error.response.data.error;
        } else if (
          error.response.data &&
          error.response.data.hasOwnProperty("result")
        ) {
          throw error.response.data;
        } else {
          throw error.response;
        }
      }
    }
  }
}
