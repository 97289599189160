import React, { useReducer, Dispatch, useEffect } from "react";
import Cookies from "universal-cookie";

import CookiePopin from "components/CookiePopin";
import Hotjar from "../cookies/Hotjar";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "store/global/types";
import { hideCookiePopin, showCookiePopin } from "store/global/actions";

type InitialStateType = {
  cookies: {
    service_hotjar: "0" | "1";
    cookies_popin_showed: "0" | "1";
  };
};
type Action = {
  type: "UPDATE_COOKIES";
  payload: Partial<InitialStateType["cookies"]>;
};

export const initialState: InitialStateType = {
  cookies: {
    service_hotjar: "0",
    cookies_popin_showed: "0",
  },
};
export const AppContext = React.createContext<{
  state: InitialStateType;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export function reducer(state: InitialStateType, action: Action) {
  switch (action.type) {
    case "UPDATE_COOKIES":
      return {
        ...state,
        cookies: { ...state.cookies, ...action.payload },
      };
    default:
      return state;
  }
}

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const cookiePopinIsOpen = useSelector(
    (state: { global: GlobalState }) => state.global.cookiePopinIsOpen,
  );

  const dispatchRedux = useDispatch();
  useEffect(() => {
    const cookies = new Cookies();
    const service_hotjar = cookies.get("service_hotjar") ?? "0";
    const cookies_popin_showed = cookies.get("cookies_popin_showed") ?? "0";

    dispatch({
      type: "UPDATE_COOKIES",
      payload: {
        service_hotjar,
        cookies_popin_showed,
      },
    });
    if (
      navigator?.cookieEnabled &&
      (!cookies_popin_showed || cookies_popin_showed !== "1")
    ) {
      dispatchRedux(showCookiePopin());
    }
  }, []);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {state.cookies.service_hotjar === "1" ? <Hotjar /> : null}
      {cookiePopinIsOpen && (
        <CookiePopin
          open={cookiePopinIsOpen}
          onClose={() => dispatchRedux(hideCookiePopin())}
        />
      )}
      {children}
    </AppContext.Provider>
  );
};
