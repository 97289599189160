import React, { useEffect, useMemo } from "react";
/* Hooks */
import { useTranslation } from "react-i18next";
/* Services */
import { getRequestsPage } from "components/Header/service";
/* Files */
import useWindowSize from "hooks/useWindowSize";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import Link from "components/CustomLink";

/* Styles */
import "./style.scss";
/* Constants */
import { MENU } from "resources/constants/common/Header";

import { ImportMessages } from "api/chat";
import { useSelector, useDispatch } from "react-redux";
import { AppState, Feedback } from "store/app/types";
import {
  getMessages,
  clearMessages,
  getFeedback,
  clearFeedback,
} from "store/app/actions";
import { useRequestInfos } from "api/request";
import { OnboardingItem } from "components/Onboarding";
import ConditionalWrapper from "components/ConditionalWrapper";
import { Tooltip } from "components/ui";
import { getRequestsEvaluationDeuxiemeavis } from "api/experts";
import {
  Heading,
  ArrowLeftIcon,
  RefreshIcon,
  FileIcon,
  ChatBubbleDoubleIcon,
} from "@carians/carians-ui";
import { DocumentsState } from "store/documents/types";

const iconArray = ["follow", "file", "comments"];

const Icon = ({ name }: { name: "follow" | "file" | "comments" }) => {
  switch (name) {
    case "follow":
      return <RefreshIcon />;
    case "file":
      return <FileIcon />;
    case "comments":
      return <ChatBubbleDoubleIcon />;
  }
  return null;
};

interface MenuItemProps {
  name: string;
  index: number;
  className: string;
  isLarge: boolean;
  isGrayed: boolean;
  request_id: string;
  messageNumber: number;
  feedback: Feedback | undefined;
}

const MenuItem = React.forwardRef(
  (
    {
      name,
      index,
      className,
      isLarge,
      isGrayed,
      request_id,
      messageNumber,
      feedback,
    }: MenuItemProps,
    ref?: React.LegacyRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation("request");
    const dispatch = useDispatch();
    const documents = useSelector(
      (state: { documents: DocumentsState }) => state.documents.list,
    );
    const handleClick = (isGrayed: boolean, url: string) => {
      if (name === "documents") {
        const link = documents?.length ? `${url}/edit` : `${url}/create`;
        dispatch(push(link));
        return;
      }
      dispatch(push(url));
    };
    return isLarge ? (
      <ConditionalWrapper
        wrapper={children => (
          <Tooltip delay={0} content={t("feedback_disabled")} nowrap>
            {children}
          </Tooltip>
        )}
        condition={name === "feedback" && isGrayed}
      >
        <div ref={ref} className={`${className}${isGrayed ? " grayed" : ""}`}>
          {isGrayed ? (
            <div>
              {name !== "chat" ? (
                t(name)
              ) : messageNumber < 1 ? (
                t(name)
              ) : (
                <div className="chat-item">
                  {t(name)} <div className="notread">{messageNumber}</div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="link"
              key={index}
              onMouseDown={() =>
                handleClick(isGrayed, `/requests/${request_id}/${name}`)
              }
            >
              {name === "chat" ? (
                messageNumber >= 1 ? (
                  <div className="chat-item">
                    {t(name)} <div className="notread">{messageNumber}</div>
                  </div>
                ) : (
                  t(name)
                )
              ) : name === "feedback" ? (
                feedback && feedback.is_read === "0" ? (
                  <div className="chat-item">
                    {t(name)} <div className="notread">{1}</div>
                  </div>
                ) : (
                  t(name)
                )
              ) : (
                t(name)
              )}
            </div>
          )}
        </div>
      </ConditionalWrapper>
    ) : (
      <ConditionalWrapper
        wrapper={children => (
          <Tooltip delay={0} content="test">
            {children}
          </Tooltip>
        )}
        condition={name === "feedback"}
      >
        <div className={`${className}${isGrayed ? " grayed" : ""}`}>
          {isGrayed ? (
            <div>
              <div className={"column"}>
                <Icon
                  customClassName={`${className}${isGrayed ? " grayed" : ""}`}
                  name={iconArray[index]}
                />
                {name !== "chat" ? null : messageNumber < 1 ? null : (
                  <div className="notread">{messageNumber}</div>
                )}
                <span
                  className={`label ${className} ${isGrayed ? "grayed" : ""}`}
                >
                  {t(name)}
                </span>
              </div>
            </div>
          ) : (
            <div
              className="link active"
              key={index}
              onMouseDown={() =>
                handleClick(isGrayed, `/requests/${request_id}/${name}`)
              }
            >
              <div className={"column"}>
                <Icon
                  customClassName={`${className}${isGrayed ? " grayed" : ""}`}
                  name={iconArray[index]}
                />
                {name !== "chat" ? null : messageNumber < 1 ? null : (
                  <div className="notread">{messageNumber}</div>
                )}
                <span
                  className={`label ${className} ${isGrayed ? "grayed" : ""}`}
                >
                  {t(name)}
                </span>
              </div>
            </div>
          )}
        </div>
      </ConditionalWrapper>
    );
  },
);

const Wrap: React.FC<{ menuitem: string; children: JSX.Element }> = ({
  children,
  menuitem,
}) => {
  const { t } = useTranslation("introduction");
  if (menuitem === "documents") {
    return (
      <OnboardingItem
        step={4}
        padding="0 12"
        title={t("followup.step_4.title")}
        message={t("followup.step_4.message")}
      >
        {children}
      </OnboardingItem>
    );
  }
  if (menuitem === "chat") {
    return (
      <OnboardingItem
        step={5}
        padding="0 12"
        title={t("followup.step_5.title")}
        message={t("followup.step_5.message")}
      >
        {children}
      </OnboardingItem>
    );
  }
  return children;
};

interface MenuProps {
  isLarge: boolean;
  request_id: string;
  status: string | null;
}
const Menu: React.FC<MenuProps> = ({ isLarge, request_id, status }) => {
  const currentPage = useMemo(() => {
    const page = getRequestsPage();

    if (!isLarge && page === "feedback") {
      return "request";
    }

    return page;
  }, [isLarge]);

  const messages = useSelector(
    (state: { app: AppState }) => state.app.messages,
  );

  const feedback = useSelector(
    (state: { app: AppState }) => state.app.feedback,
  );

  const user = useSelector((state: StoreState) => state.session.user);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchMessages = async () => {
      const messagesFetch = await ImportMessages(request_id!, "expert");
      let messageInit: {
        content: string;
        file_name: string;
        attachment: string;
        sender: string;
        date: string;
        type: string;
        author_id: string;
        author_table: string;
        show_as_read: "0" | "1";
      }[] = [];
      let newMessages: {
        content: string;
        file_name: string;
        attachment: string;
        sender: string;
        date: string;
        type: string;
        author_id: string;
        author_table: string;
        show_as_read: "0" | "1";
      }[] = [];

      if (messagesFetch.result) {
        newMessages = messagesFetch.result.map(element => {
          return {
            ...element,
            content: element.message,
            file_name: element.file_name,
            attachment: element.attachment,
            sender: element.first_name + " " + element.last_name,
            date:
              t("app:formattedDate", {
                date: new Date(element.creation_date.split(" ")[0]),
              }) +
              t("app:at") +
              element.creation_date.split(" ")[1],
            type: element.author_id === user.id ? "sent" : "received",
          };
        });
      }

      messageInit = [...messageInit, ...newMessages];
      await dispatch(getMessages(messageInit));
    };

    fetchMessages();
  }, []);

  const messageNumber = useMemo(
    () =>
      messages?.filter(
        x => x.show_as_read === "0" && x.author_table === "applicant",
      ).length || 0,
    [messages],
  );

  useEffect(() => {
    const fetchFeedback = async () => {
      const response = await getRequestsEvaluationDeuxiemeavis(request_id!);
      dispatch(getFeedback(response));
    };

    if (!feedback) {
      fetchFeedback();
    }
  }, [feedback]);

  useEffect(() => {
    return () => {
      dispatch(clearMessages());
      dispatch(clearFeedback());
    };
  }, [dispatch]);

  const isGrayed = (status: string | null, menuitem: string) => {
    if (!status) {
      return false;
    }
    if (
      ["soon_available", "available", "refused", ""].includes(status) &&
      menuitem === "chat"
    ) {
      return true;
    }

    if (
      ["soon_available", "available", "refused", ""].includes(status) &&
      menuitem === "documents"
    ) {
      return true;
    }

    if (
      ["document_sent", "closed"].includes(status) &&
      menuitem === "feedback" &&
      feedback &&
      Object.keys(feedback).length === 0
    ) {
      return true;
    }

    return false;
  };

  const menu = useMemo(() => {
    if (isLarge) {
      if (!status || !["document_sent", "closed"].includes(status)) {
        return MENU.slice(0, 3);
      } else {
        return MENU;
      }
    } else {
      return MENU.slice(0, 3);
    }
  }, [isLarge, status]);

  return (
    <>
      {menu.map((menuitem, index) => (
        <Wrap menuitem={menuitem.label} key={menuitem.uid}>
          <Heading
            as={MenuItem}
            variant="h3-bold"
            className={`${
              menuitem.label === currentPage ? "item active" : "item"
            }`}
            name={menuitem.label}
            index={index}
            isLarge={isLarge}
            isActive={menuitem.label === currentPage}
            isGrayed={isGrayed(status, menuitem.label)}
            request_id={request_id}
            messageNumber={messageNumber}
            feedback={feedback}
          />
        </Wrap>
      ))}
    </>
  );
};

function Header() {
  const windowSize = useWindowSize("string")[0] as string;
  const isLarge = ["large"].includes(windowSize);
  const { request_id } = useParams<{ request_id: string }>();
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const { data: request } = useRequestInfos(request_id, lang);
  const { t } = useTranslation("request");

  const status_code = useMemo(
    () => (request ? request.status_code : null),
    [request],
  );

  return (
    <div className="request-header">
      {isLarge && (
        <div className="item">
          <Heading as={Link} variant="h3-bold" url={"/requests"}>
            <ArrowLeftIcon mr="10px" />
            {t("list")}
          </Heading>
        </div>
      )}
      <Menu status={status_code} isLarge={isLarge} request_id={request_id} />
    </div>
  );
}

export default Header;
