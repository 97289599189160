// polyfill pour IntersectionObserver, à retirer quand on abandonne ie11 sur expert
import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { sessionService } from "redux-react-session";
import { ConnectedRouter } from "connected-react-router";
import App from "App";
import "@carians/carians-ui/dist/style.css";
import "resources/stylesheets/index.scss";

import configureStore, { history } from "store";

import AxiosConfig from "./axiosConfig";
import "./i18n";
import ErrorBoundary from "routes/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { CariansUiWrapper } from "@carians/carians-ui";

const store = configureStore();
const queryClient = new QueryClient();

const validateSession = async (session: { OTP: string; jwt: string }) => {
  if (session.OTP && session.jwt) {
    return true;
  }
  return false;
};

const options = {
  refreshOnCheckAuth: true,
  redirectPath: "/",
  driver: "COOKIES",
  validateSession: validateSession,
} as const;

sessionService
  .initSessionService(store, options)
  .then(() =>
    console.log(
      "Redux React Session is ready and a session was refreshed from your storage",
    ),
  )
  .catch(() =>
    console.log(
      "Redux React Session is ready and there is no session in your storage",
    ),
  );

AxiosConfig.setupInterceptorsRequest();
AxiosConfig.setupInterceptorsResponse(store);

ReactDOM.render(
  <CariansUiWrapper>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ErrorBoundary>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ErrorBoundary>
      </Provider>
    </QueryClientProvider>
  </CariansUiWrapper>,
  document.getElementById("root"),
);
