import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  logoRedirect?: string;
  noBorder?: boolean;
  showFooter?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  logoRedirect,
  noBorder,
  children,
  showFooter,
}) => {
  const { pathname } = useLocation();
  const renderMenu = pathname !== "/otp" && !pathname.includes("onboarding");

  return (
    <>
      <Header logoRedirect={logoRedirect} noBorder={noBorder} />
      {renderMenu ? <Menu /> : null}
      {children}
      {showFooter && <Footer noBorder={noBorder} />}
    </>
  );
};

export default Layout;
