import React, { useState, useEffect, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import { sanitize } from "dompurify";

import {
  Button,
  CheckIcon,
  InformationCircleIcon,
  WarningIcon,
  CrossIcon,
} from "@carians/carians-ui";

interface IPropsToast {
  type: string;
  message: string;
  showCloseIcon?: boolean;
  onClose?: () => void;
  autoClose?: number;
  absolutePos?: boolean;
  customClassName?: string;
  button?: { text: string; onClick: () => void } | null;
  noClose?: boolean;
  noTop?: boolean;
}
const Toast = ({
  type,
  message,
  showCloseIcon = true,
  onClose,
  autoClose = 7000,
  absolutePos = false,
  customClassName,
  button,
  noClose,
  noTop,
}: IPropsToast) => {
  const [showToast, setShowToast] = useState(false);
  const [closeToast, setCloseToast] = useState(false);
  let started: Date = new Date();
  let currentTimer: number = autoClose;
  let timer =
    currentTimer > 0 &&
    setTimeout(() => {
      setCloseToast(true);
    }, currentTimer);

  useEffect(() => {
    setShowToast(true);
  }, []);

  useEffect(() => {
    if (closeToast && !noClose) {
      setShowToast(false);
      setCloseToast(false);
      onClose && onClose();
      timer && clearTimeout(timer);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [closeToast, timer, onClose, noClose]);

  const handleMouseEnter = () => {
    const d: Date = new Date();
    currentTimer = autoClose - (d.valueOf() - started.valueOf());
    started = d;
    timer && clearTimeout(timer);
  };

  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      setCloseToast(true);
    }, currentTimer);
  };

  const Icon = useMemo(() => {
    switch (type) {
      case "success":
        return CheckIcon;
      case "info":
        return InformationCircleIcon;
      case "error":
      case "warn":
        return WarningIcon;
      default:
        return () => null;
    }
  }, [type]);

  return (
    <CSSTransition in={showToast} timeout={300} classNames="drop" unmountOnExit>
      <div
        className={`info-message ${type}${absolutePos ? " absolute " : ""}${
          customClassName ? " " + customClassName : ""
        } ${noTop ? "no-top" : ""}`}
        onMouseEnter={() => (timer ? handleMouseEnter() : null)}
        onMouseLeave={() => (timer ? handleMouseLeave() : null)}
      >
        <div className="info-message-container">
          <div className="info-message-content">
            <Icon mr="10px" />
            <div
              className="info-message-text"
              dangerouslySetInnerHTML={{
                __html: sanitize(message),
              }}
            />
          </div>
          {button && (
            <Button
              colorScheme={type === "error" ? "red" : "primary"}
              onClick={button.onClick}
            >
              {button.text}
            </Button>
          )}
        </div>
        {showCloseIcon && (
          <CrossIcon
            ml="10px"
            cursor="pointer"
            name="close"
            className="close"
            onClick={() => setCloseToast(true)}
          />
        )}
      </div>
    </CSSTransition>
  );
};

export default Toast;
