import { ReactElement } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactElement) => JSX.Element;
  children: ReactElement;
}
const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): ReactElement => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
