import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// import LanguageButton from 'components/LanguageButton';

import "./Header.scss";

import { pushMenu } from "store/global/actions";
import { GlobalState } from "store/global/types";

import { goBack, push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import Link from "components/CustomLink";
import {
  Logo,
  ArrowLeftIcon,
  MenuIcon,
  IconButton,
  Flex,
  Button,
  CrossIcon,
  useBreakpointValue,
} from "@carians/carians-ui";
import HelpButton from "components/HelpButton";

const Header = ({
  logoRedirect,
  noBorder,
}: {
  logoRedirect?: string;
  noBorder?: boolean;
}) => {
  const { t } = useTranslation("header");
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { path } = match!;
  const location = useLocation<{ from: string } | undefined>();

  const from = useMemo(() => {
    return location.state?.from;
  }, [location.state?.from]);

  const screenWidth = useSelector(
    (state: { global: GlobalState }) => state.global.screenWidth,
  );
  const checked = useSelector((state: StoreState) => state.session.checked);
  const authenticated = useSelector(
    (state: StoreState) => state.session.authenticated,
  );

  const menuIsPush = useSelector(
    (state: { global: GlobalState }) => state.global.menuIsPush,
  );

  const buttonSize = useBreakpointValue({ base: "md", lg: "sm" });

  return (
    <div className={`Header ${noBorder ? "no-border" : ""}`}>
      <div className="Header-img">
        {screenWidth === "large" ? (
          <Link url={logoRedirect ? logoRedirect : "/"}>
            <Logo width={84} />
          </Link>
        ) : path === "/requests" ||
          path === "/otp" ||
          path.includes("onboarding") ? (
          <Link url={logoRedirect ? logoRedirect : "/"}>
            <Logo width={84} />
          </Link>
        ) : path.includes("/request") ? (
          <Button
            as={Link}
            url={logoRedirect ? logoRedirect : "/"}
            leftIcon={<ArrowLeftIcon />}
            variant="outline"
            colorScheme="darkblue"
            size={buttonSize}
          >
            {t("icon.list")}
          </Button>
        ) : (
          <IconButton
            aria-label="retour en arrière"
            variant="outline"
            icon={<ArrowLeftIcon />}
            onClick={() => dispatch(from ? push(from) : goBack())}
          />
        )}
      </div>
      <Flex gap={{ base: "10px", lg: 0 }}>
        {!match!.url.includes("help") && <HelpButton />}
        {checked &&
          authenticated &&
          (screenWidth !== "large" ? (
            <IconButton
              aria-label="Ouvrir le menu"
              variant="flat"
              colorScheme="darkblue"
              icon={menuIsPush ? <CrossIcon /> : <MenuIcon />}
              onClick={() => dispatch(pushMenu())}
            />
          ) : (
            <Button
              variant="flat"
              colorScheme="darkblue"
              onClick={() => dispatch(pushMenu())}
              leftIcon={<MenuIcon />}
              size="sm"
            >
              {t("icon.menu")}
            </Button>
          ))}
      </Flex>
    </div>
  );
};

export default Header;
