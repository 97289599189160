import React from "react";

export interface OnboardingStep {
  modalOnly?: boolean;
  getRect: Element["getBoundingClientRect"];
  message: string;
  title: string;
  paddingValues: number[] | null;
}

interface IOnboardingContext {
  current: number;
  isStart: boolean;
  modalRef: unknown;
  next: () => void;
  prev: () => void;
  on: () => void;
  off: () => void;
  registerStep: (key: number, entry: OnboardingStep) => void;
}
export const OnboardingContext = React.createContext<IOnboardingContext | null>(
  null,
);
