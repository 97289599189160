import React, { useState, useEffect, useContext } from "react";
import Modal from "components/Modal";
import Divider from "components/Divider";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import "./CookiePopin.scss";

import ImageOne from "resources/images/cookie_img_one.png";
import ImageTwo from "resources/images/cookie_img_two.png";
import ImageThree from "resources/images/cookie_img_three.png";
import Img from "components/Img";
import Link from "components/Link";
import { AppContext } from "App/context";
import { Box, Switch, Text, Heading, Button } from "@carians/carians-ui";

const cookies = new Cookies();

interface CookiePopinProps {
  open: boolean;
  onClose: () => void;
}

interface ConsentState {
  service_hotjar: "0" | "1";
}

export default function CookiePopin({ open, onClose }: CookiePopinProps) {
  const { t } = useTranslation("cookies");
  const [panel, setPanel] = useState("initial");
  const [showFullAudience, setshowFullAudience] = useState(false);
  const [consents, setConsents] = useState<ConsentState>({
    service_hotjar: "0",
  });

  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const service_hotjar = cookies.get("service_hotjar");
    setConsents({
      service_hotjar: service_hotjar ? service_hotjar : "0",
    });
  }, []);

  const cookieDefaultOptions = {
    path: "/",
    maxAge: 13 * 30 * 24 * 60 * 60,
    domain:
      process.env.NODE_ENV === "production" ? ".deuxiemeavis.fr" : undefined,
  };

  function handleChangeAllCookies(value: "0" | "1") {
    cookies.set("cookies_popin_showed", "1", { ...cookieDefaultOptions });
    cookies.set("service_hotjar", value, { ...cookieDefaultOptions });

    dispatch({
      type: "UPDATE_COOKIES",
      payload: {
        service_hotjar: value,
        cookies_popin_showed: "1",
      },
    });
    onClose();
  }

  function handleAcceptCookies() {
    cookies.set("cookies_popin_showed", "1", { ...cookieDefaultOptions });
    for (const [service, value] of Object.entries(consents)) {
      cookies.set(service, value, {
        ...cookieDefaultOptions,
      });
    }
    dispatch({
      type: "UPDATE_COOKIES",
      payload: { ...consents, cookies_popin_showed: "1" },
    });
    onClose();
  }

  useEffect(() => {
    const topElement = document.getElementById("top");
    topElement?.scrollIntoView();
  }, [panel]);

  return (
    <Modal
      isVisible={open}
      onClose={() => onClose()}
      customClassName="cookies-consent"
      onOutSideClickClose={false}
      showCloseButton={false}
    >
      <div className="popup-content">
        {panel === "initial" ? (
          <>
            <div className="cookies-consent-header">
              <Heading
                variant="h2-bold"
                color="darkblue.500"
                className="cookies-consent-title"
                my="0.83em"
              >
                {t("panelOne.title")}
              </Heading>
              <Text
                as="div"
                variant="body"
                id="top"
                className="refuse-all"
                onClick={() => handleChangeAllCookies("0")}
                role="button"
                tabIndex={0}
                onKeyDown={event =>
                  event.keyCode === 13 && handleChangeAllCookies("0")
                }
              >
                {t("panelOne.refuseAll")}
              </Text>
            </div>
            <Box as="ul" mb="16px" className="cookies-consent-list">
              <Text
                as="li"
                variant="body"
                className="cookies-consent-list-item"
              >
                <Img
                  src={ImageOne}
                  className="cookies-consent-list-item-img-one"
                  alt=""
                />
                {t("panelOne.message_0")}
              </Text>
              <Text
                as="li"
                variant="body"
                className="cookies-consent-list-item"
              >
                <Img
                  src={ImageTwo}
                  className="cookies-consent-list-item-img-two"
                  alt=""
                />
                {t("panelOne.message_1")}
              </Text>
              <Text
                as="li"
                variant="body"
                className="cookies-consent-list-item"
              >
                <Img
                  src={ImageThree}
                  className="cookies-consent-list-item-img-three"
                  alt=""
                />
                <p>
                  {t("panelOne.message_2")}
                  <Link
                    url={`https://www.${
                      window.REACT_APP_ENVIRONMENT_MODE === "test"
                        ? "staging."
                        : ""
                    }deuxiemeavis.fr/doc/politique-de-confidentialite.pdf`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t("panelOne.message_3")}
                  </Link>
                </p>
              </Text>
            </Box>
            <div className="cookies-consent-controls">
              <Button
                colorScheme="darkblue"
                onClick={() => setPanel("preferences")}
                mr="20px"
              >
                {t("panelOne.btn_0")}
              </Button>
              <Button onClick={() => handleChangeAllCookies("1")}>
                {t("panelOne.btn_1")}
              </Button>
            </div>
          </>
        ) : null}
        {panel === "preferences" ? (
          <>
            <Heading
              as="h2"
              variant="h1-bold"
              color="darkblue.500"
              className="cookies-consent-title"
              id="top"
            >
              {t("panelTwo.title")}
            </Heading>
            <div className="cookies-consent-section">
              <div className="cookies-consent-section-title">
                <Heading as="h3" variant="h3-bold">
                  {t("panelTwo.functTitle")}
                </Heading>
              </div>
              <Text variant="small" className="cookies-consent-section-message">
                {t("panelTwo.functMsg")}
              </Text>
            </div>
            <div className="cookies-consent-section">
              <div className="cookies-consent-section-title">
                <Heading as="h3" variant="h3-bold">
                  {t("panelTwo.healthInsurenceTitle")}
                </Heading>
                <Text as="span" variant="h4" color="primary.500">
                  {t("panelTwo.mandatory")}
                </Text>
              </div>
              <Text variant="small" className="cookies-consent-section-message">
                {t("panelTwo.healthInsurenceMsg")}
              </Text>
            </div>
            <div className="cookies-consent-section">
              <div className="cookies-consent-section-title">
                <div className="cookies-consent-section-granular-title">
                  <Heading as="h3" variant="h3-bold">
                    {t("panelTwo.plausibleTitle")}
                  </Heading>
                  <Link
                    url="https://plausible.io/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                    validate
                  >
                    {t("panelTwo.learnMore")}
                  </Link>
                </div>
                <Text as="span" variant="h4">
                  {t("panelTwo.mandatory")}
                </Text>
              </div>
              <Text variant="small" className="cookies-consent-section-message">
                {t("panelTwo.plausibleMsg")}
              </Text>
            </div>
            <Divider small bar />
            <div className="cookies-consent-section">
              <Switch
                className="cookies-consent-section-title"
                switchProps={{
                  isChecked: consents.service_hotjar === "1",
                  onChange: e => {
                    setConsents(csts => ({
                      ...csts,
                      service_hotjar: e.target.checked ? "1" : "0",
                    }));
                  },
                }}
                leftLabelProps={{
                  flex: 1,
                  text: {
                    as: "h3",
                    fontFamily: "var(--carians-fonts-title)",
                    fontSize: [
                      "var(--carians-typography-h3-mobile-fontsize)",
                      "var(--carians-typography-h3-desktop-fontsize)",
                    ],
                    fontWeight: "700",
                    lineHeight: [
                      "var(--carians-typography-h3-mobile-lineheight)",
                      "var(--carians-typography-h3-desktop-lineheight)",
                    ],
                  },
                }}
                leftLabel={{
                  text: t("panelTwo.audienceTitle"),
                }}
              />
              <Text variant="small" className="cookies-consent-section-message">
                {t("panelTwo.audienceMsg")}
                <button
                  onClick={() => setshowFullAudience(!showFullAudience)}
                  className="see-more-btn"
                >
                  {showFullAudience
                    ? t("panelTwo.seeLess")
                    : t("panelTwo.seeMore")}
                </button>
              </Text>
              {showFullAudience ? (
                <Switch
                  className="cookies-consent-section-granular"
                  switchProps={{
                    isChecked: consents.service_hotjar === "1",
                    onChange: e => {
                      setConsents(csts => ({
                        ...csts,
                        service_hotjar: e.target.checked ? "1" : "0",
                      }));
                    },
                  }}
                  leftLabelProps={{
                    className: "cookies-consent-section-granular-title",
                    text: {
                      as: "h4",
                      fontFamily: "var(--carians-fonts-title)",
                      fontSize: [
                        "var(--carians-typography-h3-mobile-fontsize)",
                        "var(--carians-typography-h3-desktop-fontsize)",
                      ],
                      fontWeight: "700",
                      lineHeight: [
                        "var(--carians-typography-h3-mobile-lineheight)",
                        "var(--carians-typography-h3-desktop-lineheight)",
                      ],
                    },
                    hint: {
                      as: Link,
                      url: "https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information",
                      target: "_blank",
                      rel: "noreferrer noopener",
                      validate: true,
                    },
                  }}
                  leftLabel={{
                    text: t("panelTwo.hotjar"),
                    hint: t("panelTwo.learnMore"),
                  }}
                />
              ) : null}
            </div>
            <div className="cookies-consent-controls">
              <Button
                mr="20px"
                variant="flat"
                onClick={() => setPanel("initial")}
              >
                {t("panelTwo.goBack")}
              </Button>
              <Button onClick={handleAcceptCookies}>{t("panelTwo.btn")}</Button>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
}
