import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import s from "./Highlighting.module.scss";
import { Button, IconButton, CrossIcon } from "@carians/carians-ui";

const Modal: React.FC = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById("root")!);
};

interface HighlightingProps {
  children: JSX.Element;
  title: string;
  message: string;
  onClose: () => void;
  isStart: boolean;
  padding?: string;
  button?: string;
  onClickOverlay?: () => void;
}

const Highlighting: React.FC<HighlightingProps> = ({
  children,
  title,
  message,
  onClose,
  isStart,
  padding,
  button,
  onClickOverlay,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = useState<DOMRect>(null);
  const [open, setOpen] = useState(true);
  const { t } = useTranslation("introduction");

  const paddingValues = useMemo(() => {
    if (!padding) {
      return null;
    }
    const arr = padding
      .split(" ")
      .filter(Boolean)
      .map(Number)
      .filter(x => !isNaN(x));
    const [top, right, bottom, left] = arr;
    switch (arr.length) {
      case 1:
        return [top, top, top, top];
      case 2:
        return [top, right, top, right];
      case 3:
        return [top, right, bottom, right];
      case 4:
        return [top, right, bottom, left];
      default:
        return null;
    }
  }, [padding]);

  const styleHighlighting = useMemo(() => {
    if (!rect) {
      return {};
    }
    if (paddingValues) {
      const [top, right, bottom, left] = paddingValues;
      return {
        height: rect.height + top + bottom,
        left: rect.left - left,
        top: rect.top - top,
        width: rect.width + right + left,
      };
    }
    return {
      height: rect.height,
      left: rect.left,
      top: rect.top,
      width: rect.width,
    };
  }, [rect]);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setRect(ref.current.getBoundingClientRect());
  }, [ref.current]);

  const close = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  if (!open || !isStart) {
    return children;
  }
  return (
    <>
      <Modal>
        <div className={s.modal}>
          <IconButton
            aria-label="Fermer la modal"
            icon={<CrossIcon />}
            variant="flat"
            onClick={close}
            position="absolute"
            top="5px"
            right="5px"
          />
          <div className={s.container}>
            <div className={s.body}>
              <div className={s.title}>{title}</div>
              <div className={s.message}>
                {message.split("\n").map(x => (
                  <div>{x}</div>
                ))}
              </div>
            </div>
            <div className={s.btn}>
              <Button onClick={close}>{button || t("button.finish")}</Button>
            </div>
          </div>
        </div>
      </Modal>
      <div onClick={onClickOverlay} className={s.overlay} />
      <div className={s.root} style={styleHighlighting} />
      {React.cloneElement(children, {
        ref,
      })}
    </>
  );
};
export default Highlighting;
