import React from "react";
import Helmet from "react-helmet";

export default function Plausible() {
  return (
    <Helmet>
      <script
        defer
        data-domain={window.REACT_APP_PLAUSIBLE_DOMAIN}
        src={window.REACT_APP_PLAUSIBLE_SRC}
      ></script>
    </Helmet>
  );
}
