import React from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useQueryClient } from "react-query";

import { GlobalState } from "store/global/types";
import { pushMenu, closeMenu, showCookiePopin } from "store/global/actions";

import { logout } from "api/account";
import { useRouteMatch } from "react-router-dom";
import { useRequestsList } from "api/request";
import DisclosureMenu, { DisclosureItem } from "./DisclosureMenu";
import Link from "components/CustomLink";
import LinkHTML from "components/Link";
import {
  Text,
  Heading,
  CrossIcon,
  BlogIcon,
  ListBulletIcon,
  ChartBarIcon,
  GearIcon,
  ReceiptIcon,
  LockIcon,
  UserIcon,
  ScaleJusticeIcon,
  HelpIcon,
  Box,
  LogOutIcon,
} from "@carians/carians-ui";

const Menu = () => {
  const { t } = useTranslation("menu");
  const dispatch = useDispatch();

  const menuIsPush = useSelector(
    (state: { global: GlobalState }) => state.global.menuIsPush,
  );

  const user = useSelector((state: StoreState) => state.session.user);
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const { data } = useRequestsList(lang);
  const queryClient = useQueryClient();

  const sendLogout = () => {
    dispatch(pushMenu());
    logout().then(res => {
      if (res.success) {
        queryClient.clear();
        dispatch(push("/login"));
      }
    });
  };

  const match = useRouteMatch();

  const hasNoRequests = data && data.result && data.result.length === 0;

  return (
    <>
      {menuIsPush && (
        <div className="Menu-bg" onClick={() => dispatch(closeMenu())} />
      )}
      <CSSTransition
        in={menuIsPush}
        timeout={300}
        classNames="pushMenu"
        unmountOnExit
      >
        <div className="Menu">
          <CrossIcon className="close" onClick={() => dispatch(closeMenu())} />
          <div className="Menu-header">
            <div className="Menu-header-card-user">
              <Heading variant="h1-bold" color="darkblue.500">
                {t("welcome")}
              </Heading>
              <Heading variant="h1-bold" color="darkblue.500">
                {user && user.first_name}
              </Heading>
            </div>
          </div>
          {user && (
            <div className="Menu-body">
              {hasNoRequests ? (
                <Text
                  as={Link}
                  variant="body-medium"
                  className="item link"
                  url="/dashboard"
                  onClick={() => dispatch(pushMenu())}
                >
                  <BlogIcon size="sm" />
                  {t("dashboard")}
                </Text>
              ) : (
                <Text
                  as={Link}
                  variant="body-medium"
                  className="item link"
                  url="/requests"
                  onClick={() => dispatch(pushMenu())}
                >
                  <ListBulletIcon size="sm" />
                  {t("requests")}
                </Text>
              )}
              <Text
                as={Link}
                variant="body-medium"
                className="item link"
                url={{ pathname: "/metrics" }}
                onClick={() => dispatch(pushMenu())}
              >
                <ChartBarIcon size="sm" />
                {t("metrics")}
              </Text>
              <Text
                as={Link}
                variant="body-medium"
                className="item link"
                url={{ pathname: "/profile", state: { from: match!.url } }}
                onClick={() => dispatch(pushMenu())}
              >
                <GearIcon size="sm" />
                {t("account")}
              </Text>
              <Text
                as={Link}
                variant="body-medium"
                className="item link"
                url={{
                  pathname: "/expert-profile",
                  state: { from: match!.url },
                }}
                onClick={() => dispatch(pushMenu())}
              >
                <UserIcon size="sm" />
                {t("expertProfile")}
              </Text>
              <Text
                as={Link}
                variant="body-medium"
                className="item link"
                url={{
                  pathname: "/profile",
                  state: { panel: "invoice", from: match!.url },
                }}
                onClick={() => dispatch(pushMenu())}
              >
                <ReceiptIcon size="sm" />
                {t("invoice")}
              </Text>
              <Text
                as={Link}
                variant="body-medium"
                className="item link"
                url="/data-protection"
                onClick={() => dispatch(pushMenu())}
              >
                <LockIcon size="sm" />
                {t("dataProtection")}
              </Text>
              <DisclosureMenu
                icon={<ScaleJusticeIcon size="sm" />}
                label={t("infoThree")}
              >
                <DisclosureItem>
                  <Text
                    as={LinkHTML}
                    variant="body"
                    className="item link"
                    url={`https://www.${
                      window.REACT_APP_ENVIRONMENT_MODE === "test"
                        ? "staging."
                        : ""
                    }deuxiemeavis.fr/doc/mentions-legales.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("infoThree")}
                  </Text>
                </DisclosureItem>
                <DisclosureItem>
                  <Text
                    as={LinkHTML}
                    variant="body"
                    className="item link"
                    url={`https://www.${
                      window.REACT_APP_ENVIRONMENT_MODE === "test"
                        ? "staging."
                        : ""
                    }deuxiemeavis.fr/doc/conditions-generales-de-services.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("infoTwo")}
                  </Text>
                </DisclosureItem>
                <DisclosureItem>
                  <Text
                    as="div"
                    variant="body"
                    className="item link"
                    onClick={() => {
                      dispatch(showCookiePopin());
                      dispatch(pushMenu());
                    }}
                  >
                    {t("cookies")}
                  </Text>
                </DisclosureItem>
                <DisclosureItem>
                  <Text
                    as={LinkHTML}
                    variant="body"
                    className="item link"
                    url={`https://www.${
                      window.REACT_APP_ENVIRONMENT_MODE === "test"
                        ? "staging."
                        : ""
                    }deuxiemeavis.fr/doc/politique-de-confidentialite.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("infoOne")}
                  </Text>
                </DisclosureItem>
              </DisclosureMenu>
            </div>
          )}

          <Box p="8px">
            <Text
              as={Link}
              d="flex"
              alignItems="center"
              gap="8px"
              variant="body-medium"
              color="darkblue.500"
              className="item link"
              url={{ pathname: "/help", state: { from: match!.url } }}
              onClick={() => dispatch(pushMenu())}
              p="12px 16px"
            >
              <HelpIcon size="sm" /> {t("help")}
            </Text>
            <Text
              as="div"
              d="flex"
              alignItems="center"
              gap="8px"
              variant="body-medium"
              className="item link"
              color="darkblue.500"
              p="12px 16px"
              onClick={() => sendLogout()}
            >
              <LogOutIcon size="sm" />
              {t("logout")}
            </Text>
          </Box>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;
