import { Text } from "@carians/carians-ui";
import React from "react";

const Loading = ({ msg }: { msg?: string }) => {
  return (
    <>
      <div className="loading">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {msg && (
          <Text as="p" variant="body-semi-bold" className="loading-msg">
            {msg}
          </Text>
        )}
      </div>
    </>
  );
};

export default Loading;
