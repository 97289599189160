import React, { useState } from "react";
import style from "./Sidebar.module.scss";

import { SwitchTab, Box } from "@carians/carians-ui";
import FeedbackContent from "./FeedbackContent";
import Overview from "../Overview";
import { AnimatePresence, motion } from "framer-motion";
import useWindowSize from "hooks/useWindowSize";

const Sidebar = () => {
  const [switchValue, setSwitchValue] = useState<string>("feedback");
  const [windowSize] = useWindowSize("string") as [string, string];

  return (
    <div className={style.root}>
      {["small", "medium"].includes(windowSize) && (
        <Box display="flex" justifyContent="center" w="100%" p="15px">
          <SwitchTab
            switchCase={[
              {
                label: "Feedback",
                value: "feedback",
              },
              {
                label: "Compte rendu",
                value: "opinion",
              },
            ]}
            defaultValue={switchValue}
            handleChange={setSwitchValue}
          />
        </Box>
      )}
      <AnimatePresence exitBeforeEnter initial={false}>
        {switchValue === "feedback" && (
          <motion.div
            initial={{ x: -400 }}
            animate={{ x: 0 }}
            exit={{ x: -400, transition: { duration: 0.1 } }}
            key="feedback"
          >
            <FeedbackContent />
          </motion.div>
        )}

        {switchValue === "opinion" && (
          <motion.div
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            exit={{ x: 400, transition: { duration: 0.1 } }}
            key="opinion"
          >
            <Overview />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Sidebar;
