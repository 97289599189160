import { DocumentInfos } from "api/documents/documents-types";

export interface DocumentsState {
  list?: DocumentInfos[];
  isDraftOpen: boolean;
  currentDoc?: DocumentInfos;
  sendDocument: boolean;
  nature: string;
  opinion: {
    review?: string;
    reservation?: string;
    diagnostic?: string;
    consequence?: string;
    treatment?: string;
    conclusion: string;
    signature: string;
  };
  hiddenError: boolean;
  newDocSend: boolean;
  isLoading: boolean;
}
export const SET_LOADING = "SET_LOADING";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const CLEAR_CURRENT_DOC = "CLEAR_CURRENT_DOC";
export const SEND_DOCUMENT = "SEND_DOCUMENT";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
export const GET_NATURE = "GET_NATURE";
export const GET_OPINION = "GET_OPINION";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const SET_NEW_DOC_SEND = "SET_NEW_DOC_SEND";
export const OPEN_DRAFT = "OPEN_DRAFT";

interface GetDocumentsAction {
  type: typeof GET_DOCUMENTS;
  payload: DocumentsState["list"];
}
interface OpenDraft {
  type: typeof OPEN_DRAFT;
  payload: DocumentsState["isDraftOpen"];
}

interface GetDocumentAction {
  type: typeof GET_DOCUMENT;
  payload: DocumentsState["currentDoc"];
}

interface GetNatureAction {
  type: typeof GET_NATURE;
  payload: DocumentsState["nature"];
}

interface GetOpinionAction {
  type: typeof GET_OPINION;
  payload: DocumentsState["opinion"];
}

interface ClearCurrentDocAction {
  type: typeof CLEAR_CURRENT_DOC;
}

interface SendDocumentDocAction {
  type: typeof SEND_DOCUMENT;
}

interface ResetDocumentsAction {
  type: typeof RESET_DOCUMENTS;
}

interface DisplayErrorAction {
  type: typeof DISPLAY_ERROR;
}

interface SetNewDocSendAction {
  type: typeof SET_NEW_DOC_SEND;
  payload: DocumentsState["newDocSend"];
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: DocumentsState["isLoading"];
}

export type DocumentsActionTypes =
  | OpenDraft
  | GetDocumentsAction
  | GetDocumentAction
  | ClearCurrentDocAction
  | SendDocumentDocAction
  | ResetDocumentsAction
  | GetNatureAction
  | GetOpinionAction
  | DisplayErrorAction
  | SetNewDocSendAction
  | SetLoadingAction;
