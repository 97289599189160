import { createContext, Dispatch, useContext } from "react";
export interface questionsType {
  opinion:
    | "fully_agreed"
    | "mostly_agreed"
    | "another_treatment"
    | "first_diagnosis";
  treatment:
    | "fully_agreed"
    | "alternative_diagnosis"
    | "one_among_others"
    | "without_first_diagnosis";
  surgery:
    | "not_applicable"
    | "fully_agreed"
    | "agreed"
    | "not_agreed"
    | "recommended_contrariwise";
}

export type Field = "opinion" | "treatment" | "surgery";

export type State = {
  evaluationMode: boolean;
  questions: { [key: string]: string };
  currentQuestion: string;
  loadingSingleOpinion: boolean;
  showConfirm: boolean;
  validForm: boolean;
  editMode: boolean;
  isPDFEditMode: boolean;
};

type Action =
  | {
      type: "UPDATE_EVALUATION_MODE";
      payload: boolean;
    }
  | {
      type: "UPDATE_QUESTIONS";
      payload: Partial<questionsType>;
    }
  | {
      type: "UPDATE_CURRENT_QUESTION";
      payload: string;
    }
  | {
      type: "UPDATE_LOADING_SINGLE_OPINION";
      payload: boolean;
    }
  | {
      type: "UPDATE_SHOW_CONFIRM";
      payload: boolean;
    }
  | {
      type: "RESET_STATE";
    }
  | {
      type: "UPDATE_VALID_FORM";
      payload: boolean;
    }
  | {
      type: "UPDATE_EDIT_MODE";
      payload: boolean;
    }
  | {
      type: "PDF_EDIT_MODE";
      payload: boolean;
    };

interface DocumentContextType {
  state: State;
  dispatch: Dispatch<Action>;
}

export const initialState: State = {
  evaluationMode: false,
  questions: {},
  currentQuestion: "",
  loadingSingleOpinion: false,
  showConfirm: false,
  validForm: false,
  editMode: false,
  isPDFEditMode: false,
};

export const DocumentContext = createContext<Partial<DocumentContextType>>({});

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "UPDATE_EVALUATION_MODE":
      return {
        ...state,
        evaluationMode: action.payload,
      };
    case "UPDATE_QUESTIONS":
      return {
        ...state,
        questions: { ...state.questions, ...action.payload },
      };
    case "UPDATE_CURRENT_QUESTION":
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case "UPDATE_LOADING_SINGLE_OPINION":
      return {
        ...state,
        loadingSingleOpinion: action.payload,
      };
    case "UPDATE_SHOW_CONFIRM":
      return {
        ...state,
        showConfirm: action.payload,
      };
    case "RESET_STATE":
      return {
        ...initialState,
      };
    case "UPDATE_VALID_FORM":
      return {
        ...state,
        validForm: action.payload,
      };
    case "UPDATE_EDIT_MODE":
      return {
        ...state,
        editMode: action.payload,
      };
    case "PDF_EDIT_MODE":
      return {
        ...state,
        isPDFEditMode: action.payload,
      };
    default:
      return state;
  }
}

export const useDocument = () => {
  const ctx = useContext(DocumentContext);
  if (ctx === undefined) {
    throw new Error("useDocument must be to use inside DocumentProvider");
  }
  return ctx;
};
