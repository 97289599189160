import React from "react";
import { Heading, Text, StarIcon } from "@carians/carians-ui";
import { usePatientInfos, useRequestInfos } from "api/request";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getSubTitle } from "routes/Request/sub/InfoElement";
import style from "./Sidebar.module.scss";
import { useGenderList } from "api/global";
import Label from "./Label";
import { AppState } from "store/app/types";
import Loading from "components/Loading";

const FeedbackContent = () => {
  const { request_id } = useParams<{ request_id: string }>();
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const feedback = useSelector(
    (state: { app: AppState }) => state.app.feedback,
  );
  const { t } = useTranslation("feedback");
  const { t: tRequest } = useTranslation("request");
  const { data: patient } = usePatientInfos(request_id);
  const { data: request } = useRequestInfos(request_id, lang);
  const { data: genderList } = useGenderList(lang);

  const patientInfo = useMemo(() => {
    if (genderList) {
      return getSubTitle(
        patient?.is_patient === "1" ? "demander" : "patient",
        patient?.is_patient ?? "",
        patient?.patient_relation ?? "",
        patient?.birthdate ?? "",
        patient?.gender_id ?? "",
        genderList?.options,
        tRequest,
      );
    } else {
      return undefined;
    }
  }, [genderList, patient]);

  const feels = useMemo(() => {
    const feelsArray: string[][] = [[], [], [], [], []];

    if (!feedback) {
      return [];
    }

    const {
      more_reassured,
      better_informed,
      understand_better,
      ready_make_decision,
      regret_no_alternative,
      regret_no_amelioration,
    } = feedback;

    if (more_reassured === "1") {
      feelsArray[0].push("more_reassured");
    }
    if (understand_better === "1") {
      feelsArray[0].push("understand_better");
    }
    if (better_informed === "1") {
      feelsArray[1].push("better_informed");
    }
    if (ready_make_decision === "1") {
      feelsArray[2].push("ready_make_decision");
    }
    if (regret_no_alternative === "1") {
      feelsArray[3].push("regret_no_alternative");
    }
    if (regret_no_amelioration === "1") {
      feelsArray[4].push("regret_no_amelioration");
    }

    return feelsArray;
  }, [feedback]);

  const showFeels = useCallback(() => {
    let count = 0;

    feels.forEach(feel => {
      count = feel.length + count;
    });

    return !!count;
  }, [feels]);

  if (!feedback) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className={style.request_info}>
        <Text as="div" variant="small-semi-bold" color="darkblue.500">
          {t("request_number", { request_id })}
        </Text>
        <Heading
          as="div"
          variant="h2-bold"
          color="darkblue.500"
          data-hj-suppress
        >
          {request?.disease}
        </Heading>
        <Text as="div" variant="small" color="darkblue.300">
          {patientInfo}
        </Text>
      </div>
      <div className={style.feedback}>
        <Heading as="div" variant="h2-black" className={style.title}>
          <span>
            <StarIcon />
          </span>
          {t("title")}
        </Heading>
        <Label rate={feedback.satisfaction_rate} />
        {Number(feedback.satisfaction_rate) < 0 &&
          feedback.dissatisfaction_explanation && (
            <Text
              as="div"
              variant="body"
              className={style.answer}
              data-hj-suppress
            >
              {feedback.dissatisfaction_explanation}
            </Text>
          )}
        {feedback.opinion_satisfaction && (
          <>
            <Text as="div" variant="body-bold" mt="30px">
              {t("opinion_satisfaction")}
            </Text>
            <Text
              as="div"
              variant="body"
              className={style.answer}
              data-hj-suppress
            >
              {feedback.opinion_satisfaction}
            </Text>
          </>
        )}
        {showFeels() && (
          <>
            <Text as="div" variant="body-bold" mt="30px">
              {t("feels")}
            </Text>
            <div className={style.answers}>
              {feels.map((row, id) => {
                return (
                  <div className={style.row} key={`row_feel_${id}`}>
                    {row.map(feel => (
                      <Text
                        as="span"
                        variant="body"
                        key={feel}
                        className={style.answer}
                        data-hj-suppress
                      >
                        {t(feel)}
                      </Text>
                    ))}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {feedback.situation_resume && (
          <>
            <Text as="div" variant="body-bold" mt="30px">
              {t("situation_resume")}
            </Text>
            <Text
              as="div"
              variant="body"
              className={style.answer}
              data-hj-suppress
            >
              {feedback.situation_resume}
            </Text>
          </>
        )}
      </div>
    </>
  );
};

export default FeedbackContent;
