import {
  AppState,
  SET_MESSAGE_MODAL_OPEN,
  AppActionTypes,
  GET_MESSAGES,
  CLEAR_MESSAGES,
  GET_FEEDBACK,
  CLEAR_FEEDBACK,
} from "./types";

const initialState: AppState = {
  messageModalOpen: false,
  messages: undefined,
};

export function appReducer(
  state = initialState,
  action: AppActionTypes,
): AppState {
  switch (action.type) {
    case SET_MESSAGE_MODAL_OPEN:
      return {
        ...state,
        messageModalOpen: action.payload,
      };
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: undefined,
      };
    case GET_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
      };
    case CLEAR_FEEDBACK:
      return {
        ...state,
        feedback: undefined,
      };
    default:
      return state;
  }
}
