import {
  GlobalState,
  SET_SCREEN_SIZE,
  GlobalActionTypes,
  GET_OTP,
  DECREMENT_OTP_TIMER,
  ENABLE_GET_OTP,
  GET_OTP_ERROR,
  PUSH_MENU,
  CLOSE_MENU,
  STORE_STATUS_CODE,
  GET_GENDER,
  SHOW_COOKIE_POPIN,
  HIDE_COOKIE_POPIN,
  AXIOS_ERROR,
} from "./types";

const initialState: GlobalState = {
  screenWidth: "",
  screenHeight: "",
  disableGetOtp: false,
  otpInit: false,
  otpTimer: 30,
  otpSend: false,
  otpGetError: false,
  menuIsPush: false,
  status: "",
  applicant_id: "0",
  gender: undefined,
  tempPassword: undefined,
  cookiePopinIsOpen: false,
  axiosError: undefined,
};

export function globalReducer(
  state = initialState,
  action: GlobalActionTypes,
): GlobalState {
  switch (action.type) {
    case SHOW_COOKIE_POPIN:
      return {
        ...state,
        cookiePopinIsOpen: true,
      };
    case HIDE_COOKIE_POPIN:
      return {
        ...state,
        cookiePopinIsOpen: false,
      };
    case SET_SCREEN_SIZE:
      return {
        ...state,
        screenWidth: action.payload[0],
        screenHeight: action.payload[1],
      };
    case GET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case GET_OTP:
      return {
        ...state,
        otpSend: true,
        disableGetOtp: true,
        otpInit: action.payload,
      };
    case GET_OTP_ERROR:
      return {
        ...state,
        otpSend: true,
        otpGetError: true,
        disableGetOtp: true,
      };
    case DECREMENT_OTP_TIMER:
      return { ...state, otpTimer: state.otpTimer - 1 };
    case ENABLE_GET_OTP:
      return { ...state, otpTimer: 30, disableGetOtp: false };
    case PUSH_MENU:
      return { ...state, menuIsPush: !state.menuIsPush };
    case CLOSE_MENU:
      return { ...state, menuIsPush: false };
    case STORE_STATUS_CODE:
      return {
        ...state,
        status: action.payload.status_code,
        applicant_id: action.payload.applicant_id,
      };
    case AXIOS_ERROR:
      return {
        ...state,
        axiosError: action.payload,
      };
    default:
      return state;
  }
}
