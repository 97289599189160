import React, { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { RemoveScroll } from "react-remove-scroll";
import "./style.scss";
import { isIE } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Text } from "@carians/carians-ui";

interface IPropsModal {
  isVisible: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  onOutSideClickClose?: boolean;
  customClassName?: string;
}

const Modal: React.FC<IPropsModal> = ({
  isVisible,
  onClose,
  children,
  showCloseButton = true,
  onOutSideClickClose = true,
  customClassName,
}) => {
  const { t } = useTranslation("fileViewer");

  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible && modalRef) {
      modalRef.current && modalRef.current.focus();
    }
  }, [isVisible]);

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="fade" unmountOnExit>
      <RemoveScroll enabled={isVisible}>
        <div className="Modal" onClick={() => onOutSideClickClose && onClose()}>
          <div
            className={`Modal-dialog${isIE ? " ie" : ""} ${
              customClassName ? customClassName : ""
            }`}
            onClick={e => e.stopPropagation()}
          >
            {showCloseButton && (
              <Text
                as="div"
                variant="body"
                className="Modal-close"
                onClick={() => onClose()}
              >
                {t("close")}
              </Text>
            )}
            <div
              className={`Modal-content ${isIE ? " ie" : ""}`}
              ref={modalRef}
              onKeyDown={e => handleKeyDown(e)}
              tabIndex={0}
            >
              {children}
            </div>
          </div>
        </div>
      </RemoveScroll>
    </CSSTransition>
  );
};

export default Modal;
