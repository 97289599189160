import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Field, FormikErrors, useFormikContext } from "formik";
import cn from "classnames";
/* Style */
import style from "./Question.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { DocumentsState } from "store/documents/types";
import Highlighting from "components/Onboarding/highlighting";
import { useLocalStorage } from "react-use";
import removeAccents from "resources/utils/removeAccents";
import { TFunction, useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { postOpinion } from "api/documents";
import { setLoading } from "store/documents/actions";
import { TemplateOpinionEnValues } from "../en/TemplateOpinionEn";
import { mapGet, mapSet } from "resources/utils";
import { TemplateOpinionFrValues } from "../fr/TemplateOpinionFr";
import { useDocument } from "contexts/DocumentContext";
import { useDebounce } from "utils/debounce";
import Save from "./Save";
import Textarea from "components/Textarea";
import { sanitize } from "dompurify";
import { HtmlContent, Text, Heading } from "@carians/carians-ui";
interface IPropsQuestion<T> {
  question: string;
  answer: string;
  answers?: { title: string; subAnswer: string }[];
  placeholder?: string;
  id: number;
  values: T;
  autoFocus?: boolean;
  t: TFunction;
  withNum?: boolean;
  errors: FormikErrors<T>;
  icon: string;
  required?: boolean;
}

const Question = <T extends TemplateOpinionEnValues & TemplateOpinionFrValues>({
  question,
  answer,
  answers,
  placeholder,
  id,
  values,
  t,
  icon,
  required,
}: IPropsQuestion<T>) => {
  const initValue = useMemo(() => {
    return mapGet(values, `question_${id}` as keyof T) || "";
  }, [values, id]);
  const [value, setValue] = useLocalStorage<number[]>("questions", []);
  const { values: v } = useFormikContext<T>();
  const [showHighlighting, setShowHighlighting] = useState(false);
  const currentDoc = useSelector((state: { documents: DocumentsState }) => {
    return state.documents.currentDoc;
  });

  const { t: th } = useTranslation("document");
  const { request_id } = useParams<{ request_id: string }>();
  const dispatch = useDispatch();
  const { state } = useDocument();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [questionId, setQuestionId] = useState<null | string>(null);

  const saveOpinion = (values: T) => {
    let opinionSave: {
      review?: string;
      reservation?: string;
      diagnostic?: string;
      consequence?: string;
      treatment?: string;
      conclusion: string;
      signature: string;
    } = { conclusion: "", signature: "" };

    if (currentDoc?.document_type_id === "531") {
      if (values.question_1) {
        opinionSave = { ...opinionSave, review: values.question_1 };
      }
      if (values.question_2) {
        opinionSave = { ...opinionSave, reservation: values.question_2 };
      }
      if (values.question_3_1) {
        opinionSave = { ...opinionSave, diagnostic: values.question_3_1 };
      }
      if (values.question_3_2) {
        opinionSave = { ...opinionSave, treatment: values.question_3_2 };
      }
      if (values.question_3_3) {
        opinionSave = { ...opinionSave, consequence: values.question_3_3 };
      }
      if (values.question_4) {
        opinionSave = { ...opinionSave, conclusion: values.question_4 };
      }
    } else if (currentDoc?.document_type_id === "665" && values.question_1) {
      opinionSave = { ...opinionSave, conclusion: values.question_1 };
    }

    if (values.signature) {
      opinionSave = { ...opinionSave, signature: values.signature };
    }
    dispatch(setLoading(true));
    if (currentDoc?.id) {
      postOpinion(request_id, opinionSave, currentDoc.id);
    }
  };

  const saveOnChange = async (value: string, id: string) => {
    setSaving(true);
    setQuestionId(id);

    const newValues: T = { ...values };

    mapSet(newValues, id as keyof T, value);

    await saveOpinion(newValues);

    setSaved(true);
  };

  useEffect(() => {
    if (saving && saved) {
      setTimeout(() => {
        setSaving(false);
        setQuestionId(null);
      }, 5000);
    }
  }, [saving, saved]);

  const debouncedSaveOnChange = useDebounce(saveOnChange, 1000);

  useEffect(() => {
    if (id !== 1 || !currentDoc?.id) {
      return;
    }

    if (v.question_1 && !value?.includes(currentDoc?.id)) {
      const ex =
        currentDoc?.lang !== "fr"
          ? "^(dear sir|dear madam|hello|sir|madam)"
          : "^(cher monsieur|chere madame|bonjour|monsieur|madame)";
      const rgx = new RegExp(ex, "gi");
      const val = removeAccents(v.question_1.trim());
      if (rgx.test(val) && !state?.evaluationMode) {
        setShowHighlighting(true);
        setValue(prev => {
          if (!prev) {
            return [currentDoc.id];
          }
          return [...prev, currentDoc.id];
        });
        saveOnChange(v.question_1, "question_1");
        if (value) setValue([...value, currentDoc?.id]);
      } else {
        setShowHighlighting(false);
      }
    }
  }, [v, id, currentDoc?.lang, value, values]);

  const renderAnswers = (
    answers: {
      title: string;
      subAnswer: string;
      placeholder: string;
      required?: boolean;
    }[],
  ) => {
    return (
      <div className={style.subAnswers}>
        {answers.map((a, key) => (
          <div
            key={key}
            className={style.subAnswer}
            style={
              state?.evaluationMode &&
              mapGet(values, `question_${id}_${key + 1}` as keyof T) === ""
                ? { display: "none" }
                : {}
            }
          >
            <Text as="div" variant="body-bold" className={style.subLabel}>
              {a.title}
              {a.required && !state?.evaluationMode && (
                <span className={style.required}> *</span>
              )}
            </Text>
            <div className={style.textarea} data-hj-suppress>
              {!state?.evaluationMode ? (
                <Field
                  component={Textarea}
                  name={`question_${id}_${key + 1}`}
                  placeholder={a.placeholder}
                  onChange={(value: string) =>
                    debouncedSaveOnChange(value, `question_${id}_${key + 1}`)
                  }
                  textareaProps={{ color: "primary.500" }}
                />
              ) : (
                <HtmlContent
                  className={style.preview}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      mapGet(values, `question_${id}_${key + 1}` as keyof T) !==
                        ""
                        ? (mapGet(
                            values,
                            `question_${id}_${key + 1}` as keyof T,
                          ) as string)
                        : t("template.opinion.empty"),
                    ),
                  }}
                />
              )}
            </div>
            {questionId === `question_${id}_${key + 1}` && (
              <Save
                show={
                  !state?.evaluationMode &&
                  saving &&
                  saved &&
                  mapGet(values, `question_${id}_${key + 1}` as keyof T) !== ""
                }
                sub={true}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const _closeOverlay = useCallback(() => {
    setShowHighlighting(false);
  }, []);

  return (
    <Highlighting
      title={th("highlighting.check_question.title")}
      message={th("highlighting.check_question.message")}
      button={th("highlighting.check_question.btn")}
      isStart={id === 1 && showHighlighting}
      onClose={_closeOverlay}
      onClickOverlay={_closeOverlay}
      padding="10"
    >
      <div
        className={cn(style.root, {
          [style.evaluationMode]: state?.evaluationMode,
        })}
      >
        <Heading
          as="div"
          variant={state?.evaluationMode ? "h3-bold" : "h2-bold"}
          className={cn(style.label, {
            [style.evaluationMode]: state?.evaluationMode,
          })}
        >
          <span className={style.icon}>{icon}</span>
          <span>
            {`${
              currentDoc?.type !== "international-opinion" ? `${id} / ` : ""
            }${question}`}
            {!answer && required && !state?.evaluationMode && (
              <span className={style.required}> *</span>
            )}
          </span>
        </Heading>
        <div
          className={cn(style.container, {
            [style.evaluationMode]: state?.evaluationMode,
          })}
        >
          {(answer !== "" || answers) && (
            <Text as="div" variant="body-bold" className={style.answer}>
              {state?.evaluationMode && answer !== "" && answer}
              {answers ? renderAnswers(answers) : answer}
              {answer && required && !state?.evaluationMode && (
                <span className={style.required}> *</span>
              )}
            </Text>
          )}
          {!answers && (
            <div className={style.textarea} data-hj-suppress>
              {!state?.evaluationMode ? (
                <Field
                  name={`question_${id}`}
                  component={Textarea}
                  placeholder={placeholder}
                  onChange={(value: string) =>
                    debouncedSaveOnChange(value, `question_${id}`)
                  }
                  textareaProps={{ color: "primary.500" }}
                />
              ) : (
                <div
                  className={style.preview}
                  dangerouslySetInnerHTML={{
                    __html:
                      initValue !== ""
                        ? (initValue as string)
                        : t("template.opinion.empty"),
                  }}
                />
              )}
            </div>
          )}
          <Save
            show={
              !state?.evaluationMode &&
              saving &&
              saved &&
              !answers &&
              initValue !== ""
            }
          />
        </div>
      </div>
    </Highlighting>
  );
};

export default Question;
