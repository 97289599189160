import React from "react";
import Helmet from "react-helmet";

export default function Hotjar() {
  if (!process.env.NODE_ENV === "production") return null;

  return (
    <Helmet>
      <script>
        {`
         (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${
              window.REACT_APP_ENVIRONMENT_MODE === "test"
                ? "1633807"
                : "1633809"
            },hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
         `}
      </script>
    </Helmet>
  );
}
