import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Store } from "redux";
import { sessionService } from "redux-react-session";
import { push } from "connected-react-router";
import { isIE, isEdge } from "react-device-detect";
import { postErrorLogs } from "api/logs";

const displayErrorMessageFromApi = (store: Store, error: AxiosError) => {
  store.dispatch(
    push(store.getState().router.location.pathname, {
      networkError: true,
      message: error?.response?.data?.result?.message,
      translateKey: true,
      type: "info",
    }),
  );

  return Promise.reject(error);
};

const removeSessionData = async (
  sessionService: {
    loadUser: () => { [key: string]: string };
    deleteUser: () => void;
    loadSession: () => { [key: string]: string };
    deleteSession: () => void;
  },
  store: Store,
  pushOption?: { [key: string]: string | boolean },
) => {
  try {
    await sessionService.loadUser();
    sessionService.deleteUser();
    await sessionService.loadSession();
    sessionService.deleteSession();
  } catch (e) {
    console.log(e);
  }

  if (store.getState().router.location.pathname !== "/login") {
    store.dispatch(push("/login", pushOption));
  }
};

export default {
  setupInterceptorsRequest: () => {
    axios.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        if (isIE || isEdge) {
          config.headers = {
            ...config.headers,
            Pragma: `no-cache`,
          };
        }

        // For part of the application where the user hasn't yet got its OTP
        // the jwt is stored on the session user object, so we try to get it
        // from there first.
        let user: UserSessionInfos | null = null;
        try {
          user = await sessionService.loadUser();
          if (user) {
            config.params = {
              ...config.params,
              lang:
                config.params && config.params.lang
                  ? config.params.lang
                  : user.lang,
            };
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${user.jwt}`,
            };
          }
        } catch (error) {
          console.log(error);
        }

        // When the user has successfully submitted its OTP, the OTP and JWT infos
        // are stored on the session and deleted from the user object so we get them from there
        try {
          const session = await sessionService.loadSession();
          if (session) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${session.jwt}`,
              OTP: session.OTP,
            };
          }
        } catch (error) {
          console.error(error);
        }
        if (!user) {
          config.headers = {
            ...config.headers,
            Authorization: false,
          };
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  },
  setupInterceptorsResponse: (store: Store) => {
    axios.interceptors.response.use(
      response => {
        if (response.status === 401) {
          if (response.data.Error === "OTP is not valid") {
            store.dispatch(push("/otp", { logged: true }));
            return;
          }
          removeSessionData(sessionService, store, { sessionExpired: true });
          return;
        }
        if (response.status >= 400) {
          const path = store.getState().router.location.pathname;
          store.dispatch(
            push(path, {
              networkError: true,
              message: `Une erreur est survenue : (code : ${response.status}). Contactez-nous au 01 81 80 00 48.`,
            }),
          );
        }
        if (response.data === null) return;

        return response;
      },
      async error => {
        const message = error?.response?.data?.result?.message;

        if (error?.response?.status === 403) {
          if (message) {
            return displayErrorMessageFromApi(store, error);
          }

          removeSessionData(sessionService, store);

          return;
        }

        if (error?.response?.status === 418) {
          store.dispatch({
            type: `AXIOS_ERROR`,
            payload: `Une erreur est survenue. Contactez-nous au 01 81 80 00 48`,
          });
        }

        if (
          error?.response?.status === 401 &&
          error.config.url !== "/ex/accounts/login"
        ) {
          if (error.response.data.Error === "OTP is not valid") {
            store.dispatch(push("/otp", { logged: true }));
            return;
          }
          removeSessionData(sessionService, store, { sessionExpired: true });
          return Promise.reject(error);
        }
        if (
          error.response?.config.url === "/ex/accounts/login" ||
          error.response?.config.url === "/ex/accounts/otp" ||
          error.response?.config.url === "/ex/requests/data"
        ) {
          return Promise.reject(error);
        }

        if (message) {
          if (message !== "The password cannot be the same than the older")
            return displayErrorMessageFromApi(store, error);
        }

        const { request, response, code } = error;

        let path, status, msg, errorType;

        if (response) {
          const { message } = response.data;
          status = response.status;
          msg = message || "No error message is provided by the server";
          path = response.config.url || "N/A";
          errorType = "HTTP error";
        } else if (request) {
          if (error.message) {
            if (["Network Error", "Request aborted"].includes(error.message)) {
              return Promise.reject(error);
            }

            msg = `Server not accessible : "${error.message}"`;
          } else {
            msg = `Server not accessible`;
          }
          status = 503;
          path = error.config?.url || request.config?.url || "N/A";
          errorType = "Server connection error";
        } else {
          if (error.message) {
            msg = `An unexpected error has occurred : "${error.message}"`;
          } else {
            msg = `An unexpected error has occurred`;
          }

          if (error.config) {
            path = error.config.url;
          } else {
            path = "N/A";
          }

          status = 500;
          errorType = "Unexpected error";
        }

        postErrorLogs({
          req_path: store.getState().router.location.pathname,
          msg: `[${errorType}]: ${msg} (Status ${status}${
            code ? `| Code : ${code}` : ""
          }) on (${path})`,
        });

        return Promise.reject(error);
      },
    );
  },
};
