import React, { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { TemporaryMessage } from "@carians/carians-ui";
/* Hooks */
import useWindowSize from "hooks/useWindowSize";
/* Redux */
import { setScreenSize } from "store/global/actions";
/* Style */
import "./style.scss";
import { RouterRootState, push, replace } from "connected-react-router";
import i18n from "i18n";
import Toast from "components/Toast";
import Loading from "components/Loading";

import Plausible from "cookies/Plausible";
import { useTranslation } from "react-i18next";
import { AppProvider } from "./context";
import jsonPackage from "../../package.json";
import Feedback from "routes/Feedback";
import { useLoadTempMessage } from "hooks/useLoadTempMessage";

/* Components */
const Login = lazy(() => import("routes/Login"));
const Requests = lazy(() => import("routes/Requests"));
const PageNotFound = lazy(() => import("routes/PageNotFound"));
const ForgottenPassword = lazy(() => import("routes/ForgottenPassword"));
const ResetPassword = lazy(() => import("routes/ResetPassword"));
const Otp = lazy(() => import("routes/Otp"));
const Profile = lazy(() => import("routes/Profile"));
const ExpertProfile = lazy(() => import("routes/ExpertProfile"));
const PrivateRoute = lazy(() => import("routes/PrivateRoute"));
const Request = lazy(() => import("routes/Request"));
const NotAuthRoute = lazy(() => import("routes/NotAuthRoute"));
const Documents = lazy(() => import("routes/Documents"));
const Chat = lazy(() => import("routes/Chat"));
const Help = lazy(() => import("routes/Help"));
const DataProtection = lazy(() => import("routes/DataProtection"));
const CompatibilityMessage = lazy(() => import("routes/CompatibilityMessage"));
const Metrics = lazy(() => import("routes/Metrics"));
const Dashboard = lazy(() => import("routes/Dashboard"));
const OnBoarding = lazy(() => import("routes/OnBoarding"));
const HomePage = lazy(() => import("./HomePage"));

const App = () => {
  const screenSize = useWindowSize("string");
  const screenSizeNumber = useWindowSize("number");
  const dispatch = useDispatch();
  const { t } = useTranslation("apiError");

  const checked = useSelector((state: StoreState) => state.session.checked);
  const authenticated = useSelector(
    (state: StoreState) => state.session.authenticated,
  );

  const [showNetworkError, setShowNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Une erreur est survenue !");
  const [errorType, setErrorType] = useState("warn");
  const lang = useSelector((state: StoreState) => state.session.user.lang);

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const path = useSelector(
    (state: RouterRootState) => state.router.location.pathname,
  );

  const location = useLocation<{
    translateKey?: string;
    networkError?: string;
    type?: string;
    message: string;
  }>();

  const temporayMessage = useLoadTempMessage();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 300);
  }, [temporayMessage]);

  useEffect(() => {
    window.scroll(0, 0);

    if (path.includes("/connect")) {
      dispatch(push("/"));
    }
  }, [path, dispatch]);

  useEffect(() => {
    if (location.state && location.state.networkError) {
      setShowNetworkError(true);
      setErrorType(location.state.type ?? "warn");
      setErrorMessage(
        location.state.translateKey
          ? t(location.state.message)
          : location.state.message,
      );
      dispatch(replace(path));
    }
  }, [location, path, dispatch]);

  useEffect(() => {
    const targetNode = document.body;
    const setVhUnity = () => {
      const regex = /^(\d+)px$/;
      const match = regex.exec(getComputedStyle(targetNode).marginTop);
      let marginTop = 0;
      if (match) {
        marginTop = Number(match[1]);
      }
      const vh = (window.innerHeight - marginTop) * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    const observer = new MutationObserver(setVhUnity);

    const config = { attributes: true };

    observer.observe(targetNode, config);

    setVhUnity();
    document.addEventListener("resize", setVhUnity);

    return () => {
      observer.disconnect();
      document.removeEventListener("resize", setVhUnity);
    };
  }, [screenSizeNumber]);

  dispatch(setScreenSize(screenSize));

  useEffect(() => {
    console.log("version :", jsonPackage.version);
  }, []);

  return (
    <AppProvider>
      <div className="App">
        <Suspense
          fallback={
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          }
        >
          {temporayMessage && <TemporaryMessage {...temporayMessage} />}
          <Plausible />
          <CompatibilityMessage />
          <div className="App-Container">
            {checked && (
              <Switch>
                <Route exact path="/" component={HomePage} />
                <NotAuthRoute
                  exact
                  path="/login"
                  component={Login}
                  authenticated={authenticated}
                />
                <Route path="/onboarding" component={OnBoarding} />
                <Route
                  exact
                  path="/forgotten-password"
                  component={ForgottenPassword}
                />
                <Route path="/help" component={Help} />
                <Route exact path="/resetPassword" component={ResetPassword} />
                <Route exact path="/otp" component={Otp} />
                <PrivateRoute
                  exact
                  path="/profile"
                  component={Profile}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/expert-profile"
                  component={ExpertProfile}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/metrics"
                  component={Metrics}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/data-protection"
                  component={DataProtection}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/requests/:request_id/request"
                  component={Request}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  path="/requests/:request_id/documents"
                  component={Documents}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/requests/:request_id/chat"
                  component={Chat}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/requests/:request_id/feedback"
                  component={Feedback}
                  authenticated={authenticated}
                />
                <PrivateRoute
                  exact
                  path="/requests"
                  component={Requests}
                  authenticated={authenticated}
                />
                <Route component={PageNotFound} />
              </Switch>
            )}
            {showNetworkError && (
              <Toast
                message={errorMessage}
                type={errorType}
                absolutePos
                onClose={() =>
                  setTimeout(() => setShowNetworkError(false), 300)
                }
                customClassName="customToastNetwork"
                autoClose={-1}
              />
            )}
          </div>
        </Suspense>
      </div>
    </AppProvider>
  );
};

export default App;
