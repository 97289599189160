import Octopus from "./Octopus";
import { useQuery } from "react-query";

const fetchPhonecodes = () => {
  return Octopus.request("get", "/na/phonecodes").then(response => {
    if (!response) return { options: [] };
    return { options: response.result };
  });
};

export const usePhoneCodes = () =>
  useQuery("phonecodes", fetchPhonecodes, {
    retry: false,
    staleTime: 600000,
    cacheTime: 600000 /* 10mins */,
  });

export interface GenderOption {
  alt_name: string;
  code: string;
  id: string;
  name: string;
  iso_code: string;
}

async function fetchGenders(): Promise<{ options: Array<GenderOption> }> {
  const response = await Octopus.request(
    "get",
    "/na/references/generic",
    undefined,
    {
      name: "gender-type",
    },
  );
  if (!response) return { options: [] };
  return { options: response.result };
}

export const useGenderList = (lang: string) =>
  useQuery(`fetch-gender-${lang}`, fetchGenders, {
    staleTime: 600000,
    cacheTime: 600000 /* 10mins */,
    enabled: !!lang,
    retry: false,
  });

interface DocumentTypeOption {
  code: string;
  id: string;
  name: string;
}

async function fetchExpertDocumentTypes(): Promise<{
  options: Array<DocumentTypeOption>;
}> {
  const response = await Octopus.request(
    "get",
    "/na/references/generic",
    undefined,
    {
      name: "expert-document-type",
    },
  );
  if (!response) return { options: [] };
  return { options: response.result };
}

export const useExpertDocumentTypes = (lang: string) =>
  useQuery(`fetch-expert-document-types-${lang}`, fetchExpertDocumentTypes, {
    staleTime: 600000,
    cacheTime: 600000 /* 10mins */,
    enabled: !!lang,
    retry: false,
  });
