import { MedicalOpinion } from "./../../api/documents/index";
import {
  GET_DOCUMENTS,
  GET_DOCUMENT,
  CLEAR_CURRENT_DOC,
  RESET_DOCUMENTS,
  GET_OPINION,
  DISPLAY_ERROR,
  SET_NEW_DOC_SEND,
  SET_LOADING,
  OPEN_DRAFT,
} from "./types";
import { DocumentInfos } from "api/documents/documents-types";

export function getDocuments(documents: Array<DocumentInfos>) {
  return {
    type: GET_DOCUMENTS,
    payload: documents,
  };
}

export function getDocument(document: DocumentInfos) {
  return {
    type: GET_DOCUMENT,
    payload: document,
  };
}

export function getOpinion(opinion: Omit<MedicalOpinion, "request_id">) {
  return {
    type: GET_OPINION,
    payload: opinion,
  };
}

export function clearCurrentDoc() {
  return {
    type: CLEAR_CURRENT_DOC,
  };
}

export function resetDocuments() {
  return {
    type: RESET_DOCUMENTS,
  };
}
export function openDraft(payload: boolean) {
  return {
    type: OPEN_DRAFT,
    payload,
  };
}
export function displayError() {
  return {
    type: DISPLAY_ERROR,
  };
}

export function setNewDocSend(send: boolean) {
  return {
    type: SET_NEW_DOC_SEND,
    payload: send,
  };
}

export function setLoading(isLoading: boolean) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}
