import {
  DocumentsState,
  GET_DOCUMENTS,
  DocumentsActionTypes,
  GET_DOCUMENT,
  CLEAR_CURRENT_DOC,
  SEND_DOCUMENT,
  RESET_DOCUMENTS,
  GET_NATURE,
  GET_OPINION,
  DISPLAY_ERROR,
  SET_NEW_DOC_SEND,
  SET_LOADING,
  OPEN_DRAFT,
} from "./types";

const initialState: DocumentsState = {
  list: undefined,
  currentDoc: undefined,
  sendDocument: false,
  nature: "",
  opinion: { conclusion: "", signature: "" },
  hiddenError: true,
  newDocSend: false,
  isLoading: false,
  isDraftOpen: false,
};

export function documentsReducer(
  state = initialState,
  action: DocumentsActionTypes,
): DocumentsState {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_DOCUMENT:
      return {
        ...state,
        currentDoc: action.payload,
      };
    case GET_OPINION:
      return {
        ...state,
        opinion: action.payload,
      };
    case GET_NATURE:
      return {
        ...state,
        nature: action.payload,
      };
    case CLEAR_CURRENT_DOC:
      return {
        ...state,
        currentDoc: undefined,
      };
    case SEND_DOCUMENT:
      return {
        ...state,
        sendDocument: !state.sendDocument,
      };
    case DISPLAY_ERROR:
      return {
        ...state,
        hiddenError: false,
      };
    case RESET_DOCUMENTS:
      if (state.isDraftOpen) {
        return {
          ...initialState,
          currentDoc: state.currentDoc,
          isDraftOpen: true,
        };
      }
      return initialState;
    case OPEN_DRAFT:
      if (!action.payload) {
        return {
          ...state,
          isDraftOpen: action.payload,
        };
      }
      return {
        ...state,
        isDraftOpen: action.payload,
      };
    case SET_NEW_DOC_SEND:
      return {
        ...state,
        newDocSend: action.payload,
      };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
