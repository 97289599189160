import React, { AnchorHTMLAttributes, useMemo } from "react";
import { validateURL } from "resources/utils";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  url?: string | undefined;
  validate?: boolean;
}

const Link = ({ url, validate = false, ...props }: LinkProps) => {
  const validateHref = useMemo(() => {
    const href = validateURL(url ? url : "");

    if (
      validate ||
      href?.origin === window.location.origin ||
      href?.origin === window.REACT_APP_CARIANS_MEDIA_URL ||
      href?.origin ===
        `https://www.${
          window.REACT_APP_ENVIRONMENT_MODE === "test" ? "staging." : ""
        }deuxiemeavis.fr`
    ) {
      return href?.href;
    }

    return "";
  }, [url]);

  {
    /* nosemgrep-explanation: use validateHref to valide href */
  }
  return <a {...props} href={validateHref} />; // nosemgrep
};

export default Link;
