import { TemporaryMessageProps } from "@carians/carians-ui";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-use";
import Octopus from "api/Octopus";

export const useLoadTempMessage = (): TemporaryMessageProps | null => {
  const [temporayMessage, setTemporaryMessage] =
    useState<TemporaryMessageProps | null>(null);
  const called = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (called.current) {
      return;
    }
    setTimeout(() => {
      called.current = false;
    }, 30 * 1000);
  }, [called.current]);

  useEffect(() => {
    const loadTempMessage = async () => {
      const response = await Octopus.request(
        "get",
        "/na/temporary-status",
        undefined,
        { platform: "expert" },
        "json",
      );
      called.current = true;
      if (response?.result && response?.result[0]) {
        setTimeout(() => setTemporaryMessage(response.result[0]), 300);
      }
    };
    if (called.current) {
      return;
    }
    loadTempMessage();
  }, [location]);
  return temporayMessage;
};
