import React from "react";

import s from "./Error.module.scss";
import AlarmSvg from "./AlarmSvg";
import { useTranslation } from "react-i18next";
import { Logo, Button, RefreshIcon } from "@carians/carians-ui";

interface ErrorProps {
  refresh: () => void;
}

const Error: React.FC<ErrorProps> = ({ refresh }) => {
  const { t } = useTranslation("error");

  return (
    <div>
      <div className={s.menu}>
        <a href="/">
          <Logo width={84} />
        </a>
      </div>
      <div className={s.root}>
        <div data-testid="modal-error-page" className={s.modal}>
          <div className={s.divsvg}>
            <AlarmSvg width={43} height={43} />
          </div>
          <div className={s.boxtitle}>
            <p className={s.title}>{t("title")}</p>
            <p className={s.subtitle}>{t("second_subtitle")}</p>
          </div>
          <Button
            leftIcon={<RefreshIcon />}
            onClick={() => refresh()}
            variant="outline"
            className={s.button}
          >
            {t("button")}
          </Button>
          <div className={s.problem}>
            <span className={s.text}>{t("problem.text")}</span>
            <span className={s.phone}>{t("problem.phone")}</span>
            <span className={s.text}>{t("problem.text_2")}</span>
            <span className={s.mail}>{t("problem.mail")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
