import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@carians/carians-ui";

interface LabelProps {
  rate: "-2" | "-1" | "1" | "2";
}

const Label = ({ rate }: LabelProps) => {
  const { t } = useTranslation("feedback");

  const { color, bgColor } = useMemo(() => {
    switch (rate) {
      case "-2":
        return { color: "secondary.500", bgColor: "secondary.50" };
      case "-1":
        return { color: "orange.500", bgColor: "orange.50" };
      default:
        return { color: "primary.500", bgColor: "primary.50" };
    }
  }, [rate]);

  return (
    <Box
      textStyle="body-bold"
      borderRadius="12px"
      p="10px 16px"
      width="fit-content"
      color={color}
      bgColor={bgColor}
    >
      {t(`satisfaction_rate.${rate}`)}
    </Box>
  );
};

export default Label;
