import React, { useMemo } from "react";
import { validateURL } from "resources/utils";
import { Link } from "react-router-dom";
import * as H from "history";

interface CustomLinkProps<S = H.LocationState>
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url:
    | H.LocationDescriptor<S>
    | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  component?: React.ComponentType<unknown>;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
  children?: React.ReactNode;
}

const CustomLink = ({ url, children, ...props }: CustomLinkProps) => {
  const validateHref = useMemo(() => {
    const href = typeof url === "string" ? url : url.pathname;
    const urlVerif = validateURL(href);

    if (
      urlVerif?.origin === window.location.origin ||
      urlVerif?.origin === window.REACT_APP_CARIANS_MEDIA_URL
    ) {
      return url;
    }

    return "";
  }, [url]);

  return (
    <Link to={validateHref} {...props}>
      {children}
      {/* nosemgrep-explanation: use validateHref to valide link */}
    </Link> // nosemgrep
  );
};

export default CustomLink;
