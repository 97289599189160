import React, { useEffect, useState } from "react";

import { useApplicantInfos } from "api/request";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useRequestInfos } from "api/request";
import { TFunction } from "react-i18next";
import { RequestData } from "api/request/request-types";

interface IPropsIntroduction {
  type: string;
  t: TFunction;
}

const Introduction = ({ type, t }: IPropsIntroduction) => {
  const { request_id } = useParams<{ request_id: string }>();
  const [genderIntro, setGenderIntro] = useState<string>();
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const [disease, setDisease] = useState<RequestData["disease"]>();

  const { data: request_data } = useRequestInfos(request_id, lang);
  const { data: applicant } = useApplicantInfos(request_id);

  useEffect(() => {
    if (applicant) {
      if (applicant.gender_id === "525") {
        setGenderIntro("text_1_2");
      } else if (applicant.gender_id === "526") {
        setGenderIntro("text_1_1");
      } else {
        setGenderIntro("text_1_3");
      }
    }

    if (!disease && request_data && request_data.disease) {
      setDisease(request_data.disease);
    }
  }, [request_id, disease, genderIntro, request_data, applicant]);

  return (
    <>
      {type !== "quotate" && (
        <>
          {t(`template.${type}.proofreading.introduction.${genderIntro}`)}
          <br />
          <br />
          {t(`template.${type}.proofreading.introduction.text_2`)}
          <b data-hj-suppress>{disease}</b>
          <br />
          <br />
          {t(`template.${type}.proofreading.introduction.text_3`)}
        </>
      )}
      {type === "quotate" && (
        <>
          {t(`template.quotate.resum.${genderIntro}`)}
          <br />
          <br />
          {t(`template.quotate.resum.text_2`)}
        </>
      )}
    </>
  );
};

export default Introduction;
