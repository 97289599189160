import React from "react";
import { Input, FormControlWrapperProps } from "@carians/carians-ui";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik";
import { InputProps } from "@chakra-ui/input";

const CustomInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setStatus, submitCount, setFieldTouched },
  showError = false, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  inputProps,
  ...props
}: FieldProps &
  FormControlWrapperProps & {
    showError?: boolean;
    label?: string;
    placeholder?: string;
    type?: string;
    onFocus?: () => void;
    onBlur?: (e: string) => void;
    onChange?: (e: string) => void;
    size?: string;
    regex?: string;
    widthFitContent?: boolean;
    optional?: boolean;
    disabled?: boolean;
    inputProps?: InputProps;
  }) => {
  const { t } = useTranslation(["form", "login", "forgottenPassword"]);
  let className = "input";

  props.size && (className += " " + props.size);

  (showError && errors[field.name]) ||
    (errors[field.name] && submitCount > 0 && (className += " error"));

  if (props.type === "hidden") {
    className += " hidden";
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.regex) {
      if (e.target.value.match(props.regex) === null) {
        e.preventDefault();
        return;
      }
    }

    if (props.onChange) {
      props.onChange(e.target.value);
    }
    if (props.widthFitContent) {
      e.target.size = e.target.value.length > 10 ? e.target.value.length : 10;
    }
    if (field) {
      setStatus("");
      setFieldTouched(field.name, false);
      field.onChange(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(e.target.value);
    }

    field.onBlur(e);
  };

  const handleFocus = () => {
    props.onFocus && props.onFocus();
  };

  const error = () => {
    if (
      touched[field.name] &&
      props.type !== "hidden" &&
      errors[field.name] &&
      submitCount > 0
    ) {
      return true;
    } else return false;
  };

  return (
    <Input
      {...props}
      label={props.label}
      placeholder={props.placeholder}
      inputProps={{
        ...field,
        ...inputProps,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange,
      }}
      rightLabel={props.optional ? t("form.optional") : undefined}
      isDisabled={props.disabled}
      isInvalid={error() || !!(errors[field.name] && showError)}
      errorMessage={t(errors[field.name] as string)}
    />
  );
};

export default CustomInput;
