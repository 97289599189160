import * as React from "react";

function AlarmSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.6232 38.6464H7.37622V22.1259C7.37622 15.0971 13.0739 9.39941 20.1024 9.39941H23.897C30.9254 9.39941 36.6232 15.0971 36.6232 22.1259V38.6464Z"
        fill="url(#paint0_linear_3068_1822)"
      />
      <path
        d="M33.0428 38.6464H10.9562V20.4424C10.9562 14.3435 15.9007 9.39941 21.9995 9.39941C28.0984 9.39941 33.0428 14.3435 33.0428 20.4424V38.6464Z"
        fill="url(#paint1_linear_3068_1822)"
      />
      <path
        d="M33.0428 38.6464H10.9562V20.4424C10.9562 14.3435 15.9007 9.39941 21.9995 9.39941C28.0984 9.39941 33.0428 14.3435 33.0428 20.4424V38.6464Z"
        fill="url(#paint2_linear_3068_1822)"
      />
      <path
        d="M26.7452 38.6464H17.2551V14.1444C17.2551 11.5237 19.3794 9.39941 22.0002 9.39941C24.6209 9.39941 26.7452 11.5237 26.7452 14.1444V38.6464Z"
        fill="url(#paint3_linear_3068_1822)"
      />
      <path
        d="M28.0259 38.6468V21.9866C28.0259 18.6586 25.3282 15.9609 22.0002 15.9609C18.6721 15.9609 15.9745 18.6586 15.9745 21.9866V38.6468H28.0259Z"
        fill="url(#paint4_linear_3068_1822)"
      />
      <path
        d="M7.37622 27.9502H36.6232V38.6467H7.37622V27.9502Z"
        fill="url(#paint5_linear_3068_1822)"
      />
      <path
        d="M39.1796 40.5815H4.82007C4.19736 40.5815 3.69281 40.0766 3.69281 39.4542C3.69281 37.9399 4.92044 36.7119 6.43475 36.7119H37.5649C39.0793 36.7119 40.3069 37.9399 40.3069 39.4542C40.3069 40.0766 39.8023 40.5815 39.1796 40.5815Z"
        fill="url(#paint6_linear_3068_1822)"
      />
      <path
        d="M42.8727 43.252H1.12726C0.504546 43.252 0 42.7475 0 42.1248C0 40.6104 1.22763 39.3828 2.74194 39.3828H41.258C42.7723 39.3828 43.9999 40.6104 43.9999 42.1248C43.9999 42.7475 43.4954 43.252 42.8727 43.252Z"
        fill="url(#paint7_linear_3068_1822)"
      />
      <path
        d="M22 8.08517C21.4044 8.08517 20.9214 7.60245 20.9214 7.00693V1.76608C20.9214 1.17056 21.4044 0.6875 22 0.6875C22.5955 0.6875 23.0786 1.17056 23.0786 1.76608V7.00693C23.0786 7.60245 22.5955 8.08517 22 8.08517Z"
        fill="url(#paint8_linear_3068_1822)"
      />
      <path
        d="M30.7632 9.02846C30.5249 9.02846 30.2852 8.94991 30.0854 8.78844C29.6225 8.4138 29.551 7.7347 29.9256 7.27177L33.2228 3.1978C33.5975 2.73488 34.2766 2.66338 34.7395 3.03801C35.2024 3.41264 35.2739 4.09175 34.8993 4.55467L31.6021 8.62865C31.3889 8.89183 31.0774 9.02846 30.7632 9.02846Z"
        fill="url(#paint9_linear_3068_1822)"
      />
      <path
        d="M13.2373 9.02846C12.9231 9.02846 12.6116 8.89183 12.3984 8.62865L9.10124 4.55467C8.72661 4.09175 8.79811 3.41264 9.26103 3.03801C9.72429 2.66338 10.4031 2.73488 10.7777 3.1978L14.0749 7.27177C14.4498 7.7347 14.378 8.4138 13.9151 8.78844C13.7153 8.94991 13.4757 9.02846 13.2373 9.02846Z"
        fill="url(#paint10_linear_3068_1822)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3068_1822"
          x1="13.8229"
          y1="18.2699"
          x2="35.6858"
          y2="44.5054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E3A" />
          <stop offset="0.1596" stopColor="#FF6440" />
          <stop offset="0.4266" stopColor="#FF4B52" />
          <stop offset="0.7662" stopColor="#FF216E" />
          <stop offset="1" stopColor="#FF0084" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3068_1822"
          x1="19.0662"
          y1="24.023"
          x2="-43.7438"
          y2="24.023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0084" stopOpacity="0" />
          <stop offset="0.0737" stopColor="#F4087E" stopOpacity="0.07451" />
          <stop offset="0.3347" stopColor="#D2216B" stopOpacity="0.333333" />
          <stop offset="0.583" stopColor="#BA335E" stopOpacity="0.584314" />
          <stop offset="0.8111" stopColor="#AB3D56" stopOpacity="0.811765" />
          <stop offset="1" stopColor="#A64153" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3068_1822"
          x1="28.4701"
          y1="24.023"
          x2="60.2811"
          y2="24.023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0084" stopOpacity="0" />
          <stop offset="0.0737" stopColor="#F4087E" stopOpacity="0.07451" />
          <stop offset="0.3347" stopColor="#D2216B" stopOpacity="0.333333" />
          <stop offset="0.583" stopColor="#BA335E" stopOpacity="0.584314" />
          <stop offset="0.8111" stopColor="#AB3D56" stopOpacity="0.811765" />
          <stop offset="1" stopColor="#A64153" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3068_1822"
          x1="22.5948"
          y1="24.0553"
          x2="46.234"
          y2="24.3141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0084" stopOpacity="0" />
          <stop offset="0.0737" stopColor="#F4087E" stopOpacity="0.07451" />
          <stop offset="0.3347" stopColor="#D2216B" stopOpacity="0.333333" />
          <stop offset="0.583" stopColor="#BA335E" stopOpacity="0.584314" />
          <stop offset="0.8111" stopColor="#AB3D56" stopOpacity="0.811765" />
          <stop offset="1" stopColor="#A64153" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3068_1822"
          x1="15.5296"
          y1="27.3038"
          x2="35.0706"
          y2="27.3038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0084" stopOpacity="0" />
          <stop offset="0.0737" stopColor="#F4087E" stopOpacity="0.07451" />
          <stop offset="0.3347" stopColor="#D2216B" stopOpacity="0.333333" />
          <stop offset="0.583" stopColor="#BA335E" stopOpacity="0.584314" />
          <stop offset="0.8111" stopColor="#AB3D56" stopOpacity="0.811765" />
          <stop offset="1" stopColor="#A64153" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3068_1822"
          x1="21.9997"
          y1="32.6954"
          x2="21.9997"
          y2="38.3922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0084" stopOpacity="0" />
          <stop offset="0.7133" stopColor="#C02E62" stopOpacity="0.713725" />
          <stop offset="1" stopColor="#A64153" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3068_1822"
          x1="21.3862"
          y1="36.5834"
          x2="22.7307"
          y2="42.6784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45425A" />
          <stop offset="1" stopColor="#2D3251" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3068_1822"
          x1="21.3141"
          y1="38.9268"
          x2="22.8544"
          y2="45.9095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45425A" />
          <stop offset="1" stopColor="#2D3251" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3068_1822"
          x1="22"
          y1="0.547071"
          x2="22"
          y2="9.75131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E3A" />
          <stop offset="0.1596" stopColor="#FF6440" />
          <stop offset="0.4266" stopColor="#FF4B52" />
          <stop offset="0.7662" stopColor="#FF216E" />
          <stop offset="1" stopColor="#FF0084" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3068_1822"
          x1="33.771"
          y1="4.22746"
          x2="27.9806"
          y2="11.3819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E3A" />
          <stop offset="0.1596" stopColor="#FF6440" />
          <stop offset="0.4266" stopColor="#FF4B52" />
          <stop offset="0.7662" stopColor="#FF216E" />
          <stop offset="1" stopColor="#FF0084" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3068_1822"
          x1="10.2312"
          y1="4.2257"
          x2="16.0216"
          y2="11.3801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6E3A" />
          <stop offset="0.1596" stopColor="#FF6440" />
          <stop offset="0.4266" stopColor="#FF4B52" />
          <stop offset="0.7662" stopColor="#FF216E" />
          <stop offset="1" stopColor="#FF0084" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AlarmSvg;
