import React, { useEffect, useRef, useMemo } from "react";
import { OnboardingContext } from "../context";

interface OnboardingItemProps {
  step: number;
  children: JSX.Element;
  message: string;
  title: string;
  padding?: string;
}

const OnboardingItem: React.FC<OnboardingItemProps> = ({
  children,
  step,
  message,
  title,
  padding,
}) => {
  const ctx = React.useContext(OnboardingContext);
  const ref = useRef<HTMLDivElement | null>(null);
  if (ctx === null) {
    return <>{children}</>;
  }
  const { current, registerStep } = ctx;
  useEffect(() => {
    if (current === step && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [current, ref.current]);

  const paddingValues = useMemo(() => {
    if (!padding) {
      return null;
    }
    const arr = padding
      .split(" ")
      .filter(Boolean)
      .map(Number)
      .filter(x => !isNaN(x));
    const [top, right, bottom, left] = arr;
    switch (arr.length) {
      case 1:
        return [top, top, top, top];
      case 2:
        return [top, right, top, right];
      case 3:
        return [top, right, bottom, right];
      case 4:
        return [top, right, bottom, left];
      default:
        return null;
    }
  }, [padding]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    registerStep(step, {
      getRect: () => ref.current?.getBoundingClientRect(),
      message,
      title,
      paddingValues,
    });
  }, [ref.current, step, registerStep, paddingValues]);

  return React.cloneElement(children, {
    ref,
  });
};
export default OnboardingItem;
