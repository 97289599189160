import React from "react";

import style from "./QuestionsAsked.module.scss";
import { TFunction } from "react-i18next";
import { useParams } from "react-router";
import { useRequestDescription } from "api/request";
import { Text } from "@carians/carians-ui";

interface IPropsQuestionsAsked {
  type: string;
  t: TFunction;
}

const QuestionsAsked = ({ type, t }: IPropsQuestionsAsked) => {
  const { request_id } = useParams<{ request_id: string }>();
  const { data: nature } = useRequestDescription(request_id);

  return (
    <div className={style.root}>
      <Text as="div" variant="body-bold" mb="20px" className={style.text}>
        {t(`template.${type}.proofreading.questionsAsked`)}
      </Text>
      <div className={style.container}>
        <img src="/images/quote.svg" />
        <Text as="span" data-hj-suppress whiteSpace="pre-wrap">
          {nature}
        </Text>
      </div>
    </div>
  );
};

export default QuestionsAsked;
