import React, { useEffect } from "react";
import style from "./Feedback.module.scss";
import Layout from "components/Layout";
import useWindowSize from "hooks/useWindowSize";
import Header from "components/Header";
import Sidebar from "./Sidebar";
import Overview from "./Overview";
import { useRequestInfos } from "api/request";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/app/types";
import {
  getRequestsEvaluationDeuxiemeavis,
  putRequestsEvaluationDeuxiemeavisSetAsRead,
} from "api/experts";
import { getFeedback } from "store/app/actions";
import { push } from "connected-react-router";
import {
  clearCurrentDoc,
  getDocument,
  getOpinion,
} from "store/documents/actions";
import { fetchOpinion, getListDocuments } from "api/documents";

const Feedback = () => {
  const { request_id } = useParams<{ request_id: string }>();
  const lang = useSelector((state: StoreState) => state.session.user.lang);
  const [windowSize] = useWindowSize("string") as [string, string];
  const { data: request } = useRequestInfos(request_id, lang);
  const dispatch = useDispatch();

  const feedback = useSelector(
    (state: { app: AppState }) => state.app.feedback,
  );

  useEffect(() => {
    const getData = async () => {
      const response = await getRequestsEvaluationDeuxiemeavis(request_id!);
      dispatch(getFeedback(response));
    };

    const setAsRead = async () => {
      const { success } = await putRequestsEvaluationDeuxiemeavisSetAsRead(
        request_id,
      );

      if (success) {
        await getData();
      }
    };

    if (!feedback) {
      getData();
    } else {
      if (feedback.is_read === "0") {
        setAsRead();
      }
    }
  }, [feedback]);

  useEffect(() => {
    const getData = async () => {
      const { result } = await getListDocuments(request_id);

      if (result) {
        const currentDoc = result.reverse().find(doc => doc.draft === "0");

        if (currentDoc) {
          const currentOpnion = await fetchOpinion(request_id!, currentDoc.id);

          dispatch(getDocument({ ...currentDoc, request_id }));
          dispatch(getOpinion(currentOpnion));
        }
      }
    };

    if (request && feedback) {
      if (
        !["document_sent", "closed"].includes(request.status_code) ||
        (feedback && Object.keys(feedback).length === 0)
      ) {
        dispatch(push(`/requests/${request_id}/request`));
      } else {
        getData();
      }
    }

    return () => {
      dispatch(clearCurrentDoc());
    };
  }, [request, feedback]);

  return (
    <Layout>
      <div className={style.root}>
        {["large"].includes(windowSize) && <Header />}
        <div className={style.main}>
          <Sidebar />
          {["large"].includes(windowSize) && (
            <div className={style.right}>
              <Overview />
            </div>
          )}
        </div>
        {["small", "medium"].includes(windowSize) && <Header />}
      </div>
    </Layout>
  );
};

export default Feedback;
