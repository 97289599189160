import React from "react";
import { useSelector } from "react-redux";
import { DocumentsState } from "store/documents/types";

import style from "./DocumentTitle.module.scss";
import { useParams } from "react-router";
import i18n from "i18n";
import { Text, Heading } from "@carians/carians-ui";

interface DocumentTitleProps {
  type: string;
}

const DocumentTitle: React.FC<DocumentTitleProps> = ({ type }) => {
  const t = i18n.getFixedT("fr", "document");
  const { request_id } = useParams<{ request_id: string }>();
  const currentDoc = useSelector(
    (state: { documents: DocumentsState }) => state.documents.currentDoc,
  );

  if (!currentDoc) {
    return null;
  }
  return (
    <div className={style.root}>
      <Text as="div" variant="body" className={style.request_id}>
        {t("template.title.request")}
        {request_id}
      </Text>
      <Heading as="div" variant="h1-bold" mb="20px">
        {currentDoc.name}
      </Heading>
      {type !== "quotate" && (
        <Text as="div" variant="body" mb="15px" className={style.info}>
          {t(`template.title.${type === "opinion" ? "info_1" : "info_2"}`)}
        </Text>
      )}
    </div>
  );
};

export default DocumentTitle;
