import React, { useEffect, useRef } from "react";
// import { CSSTransition } from "react-transition-group";
import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { Textarea as CariansTextarea } from "@carians/carians-ui";
import useAutoResizeTextarea from "hooks/useAutoResizeTextarea";

interface TextareaCustomProps extends FieldProps {
  showError?: boolean;
  onChange?: (e: string) => void;
  onClick?: (e: React.ChangeEvent) => void;
  answers: { label: string; value: string; labelSub?: string }[];
  label?: string;
  labelSub?: string;
  autoFocus?: boolean;
  onBlur?: (e: string) => void;
  onFocus?: (e: string) => void;
  sublabel?: string;
  optional?: boolean;
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  textareaProps?: { [key: string]: unknown };
}

const Textarea: React.FC<TextareaCustomProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  showError = false,
  ...props
}) => {
  const { t } = useTranslation(["form", "login", "forgottenPassword"]);

  const refTextarea = useRef<HTMLTextAreaElement>(null);

  useAutoResizeTextarea(refTextarea, props.placeholder ?? "");

  useEffect(() => {
    if (props.autoFocus && refTextarea.current) {
      const value = refTextarea.current.value.trim();
      refTextarea.current.focus();
      refTextarea.current.setSelectionRange(value.length, value.length);
    }
  }, [props.autoFocus]);

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    props.onBlur && props.onBlur(event.currentTarget.value.trim());
    field.onBlur(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    props.onFocus &&
      props.onFocus((event.currentTarget as HTMLTextAreaElement).value.trim());
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange && props.onChange(event.currentTarget.value.trim());
    field.onChange(event);
  };

  const error = () => {
    if (touched[field.name] && errors[field.name]) {
      return true;
    } else return false;
  };

  return (
    <CariansTextarea
      mb="0"
      isDisabled={props.disabled}
      textareaProps={{
        ...field,
        ...props.textareaProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onChange: handleChange,
        resize: "none",
        pb: "25px",
        mb: 0,
      }}
      isInvalid={showError && (error() || (!!errors[field.name] && showError))}
      errorMessage={t(errors[field.name] as string)}
      rightLabel={props.optional ? t("optional") : undefined}
      placeholder={
        props.placeholder ? props.placeholder : t("textarea.placeholder")
      }
      ref={refTextarea}
    />
  );
};

export default Textarea;
