import React, { useEffect } from "react";

const useAutoResizeTextarea = (
  ref: React.RefObject<HTMLTextAreaElement>,
  placeholder: string,
  value?: string,
  autoResize = true,
) => {
  useEffect(() => {
    if (ref.current) {
      if (value && !autoResize) {
        return;
      }

      ref.current.setAttribute(
        "style",
        "height:" + ref.current.scrollHeight + `px;overflow-y:hidden;`,
      );

      OnInput(ref.current);

      if (!autoResize) {
        ref.current.setAttribute(
          "style",
          "height:" + ref.current.scrollHeight + `px;`,
        );
      }

      autoResize &&
        ref.current.addEventListener(
          "input",
          () => OnInput(ref.current),
          false,
        );
    }

    () =>
      autoResize &&
      ref.current?.removeEventListener(
        "input",
        () => OnInput(ref.current),
        false,
      );
  }, [ref.current, value]);

  const OnInput = (el: HTMLTextAreaElement | null) => {
    if (!el) return;

    if (el.value === "") {
      const tx = document.createElement("textarea");
      document.body.appendChild(tx);

      tx.style.width = `${el.clientWidth}px`;
      tx.style.padding = `10px 15px 25px 10px`;
      tx.value = placeholder;

      el.setAttribute(
        "style",
        "height:" + tx.scrollHeight + "px;overflow-y:hidden;",
      );

      document.body.removeChild(tx);
    } else {
      el.style.height = "0";
      el.style.height = el.scrollHeight + "px";
    }
  };
};

export default useAutoResizeTextarea;
