import React from "react";
import { TFunction } from "react-i18next";
import { useParams } from "react-router";

import style from "./Resum.module.scss";
import { useRequestDescription } from "api/request";
import { Text } from "@carians/carians-ui";

interface IPropsResum {
  type: string;
  t: TFunction;
}

const Resum = ({ type, t }: IPropsResum) => {
  const { request_id } = useParams<{ request_id: string }>();
  const { data: nature } = useRequestDescription(request_id);

  return (
    <Text as="div" variant="body" className={style.root}>
      <Text as="div" variant="body-bold" mb="10px">
        {t(`template.${type}.resum.title`)}
      </Text>
      <Text as="span" data-hj-suppress whiteSpace="pre-wrap">
        {nature}
      </Text>
    </Text>
  );
};

export default Resum;
