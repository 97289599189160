import React, { useEffect } from "react";
import { Formik, FormikHelpers, Form, FormikErrors } from "formik";
import { useDispatch, useSelector } from "react-redux";
/* Components */
import Resum from "../Resum";
import Questions from "./Questions";
import Signature from "../Signature";
import DocumentTitle from "../DocumentTitle";
/* Types */
import { DocumentsState } from "store/documents/types";
/* Style */
import style from "../fr/TemplateOpinionFr.module.scss";
import cn from "classnames";
import { postOpinion } from "api/documents";
import { useParams } from "react-router";
import { setLoading } from "store/documents/actions";
import { TFunction } from "react-i18next";
import { useDocument } from "contexts/DocumentContext";
import Title from "../Title";
import Introduction from "../Introduction";
import QuestionsAsked from "../QuestionsAsked";
import { Box } from "@carians/carians-ui";
import { getTrimValues } from "utils/trimValues";

export interface TemplateOpinionEnValues {
  question_1: string;
  signature: string;
}

interface IPropsTemplateOpinionEn {
  t: TFunction;
}

const TemplateOpinionEn: React.FC<IPropsTemplateOpinionEn> = ({ t }) => {
  const { first_name, last_name } = useSelector(
    (state: StoreState) => state.session.user,
  );
  const { dispatch: documentDispatch, state } = useDocument();

  const dispatch = useDispatch();
  const { request_id } = useParams<{ request_id: string }>();

  const initialValues: TemplateOpinionEnValues = {
    question_1: "",
    signature: (first_name && last_name && first_name + " " + last_name) || "",
  };

  const currentDoc = useSelector(
    (state: { documents: DocumentsState }) => state.documents.currentDoc,
  );

  const opinion = useSelector(
    (state: { documents: DocumentsState }) => state.documents.opinion,
  );

  if (opinion.conclusion) {
    initialValues.question_1 = opinion.conclusion;
  }
  if (opinion.signature) {
    initialValues.signature = opinion.signature;
  }

  const handleSubmit = async (
    values: TemplateOpinionEnValues,
    actions: FormikHelpers<TemplateOpinionEnValues>,
  ) => {
    saveOpinion(values);
    actions.setSubmitting(false);
  };

  const validate = (values: TemplateOpinionEnValues) => {
    const errors: FormikErrors<TemplateOpinionEnValues> = {};
    const trimValues = getTrimValues(values);

    if (trimValues.question_1 === "") {
      errors.question_1 = "form:required";
    }

    if (values.signature === "") {
      errors.signature = "form:required";
    }

    if (Object.keys(errors).length === 0) {
      documentDispatch &&
        documentDispatch({ type: "UPDATE_VALID_FORM", payload: true });
    } else {
      documentDispatch &&
        documentDispatch({ type: "UPDATE_VALID_FORM", payload: false });
    }

    return errors;
  };

  const saveOpinion = async (values: TemplateOpinionEnValues) => {
    if (!currentDoc) {
      return;
    }
    let interSave: {
      conclusion: string;
      signature: string;
    } = { conclusion: "", signature: "" };

    if (values.question_1) {
      interSave = { ...interSave, conclusion: values.question_1 };
    }

    if (values.signature) {
      interSave = { ...interSave, signature: values.signature };
    }

    dispatch(setLoading(true));
    await postOpinion(request_id, interSave, currentDoc.id);
    dispatch(setLoading(false));
  };
  if (!currentDoc) {
    return null;
  }

  useEffect(() => {
    validate(initialValues);
  }, [currentDoc, location]);

  return (
    <Box
      textStyle="body"
      className={cn(style.root, {
        [style.evaluationMode]: state?.evaluationMode,
      })}
    >
      {!state?.evaluationMode && <DocumentTitle type="inter" />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors }) => (
          <Form>
            {!state?.evaluationMode ? (
              <Resum type="inter" t={t} />
            ) : (
              <>
                <Title type="inter" t={t} />
                <Introduction type="inter" t={t} />
                <QuestionsAsked type="inter" t={t} />
              </>
            )}
            <Questions values={values} t={t} errors={errors} />
            {state?.evaluationMode &&
              t(`template.inter.proofreading.conclusion`)}
            <Signature
              type="inter"
              saveOpinion={saveOpinion}
              values={values}
              t={t}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TemplateOpinionEn;
