import { DocumentInfos } from "api/documents/documents-types";
import { MedicalFileInfos, RequestData } from "api/request/request-types";

export interface RequestState {
  documents: Array<MedicalFileInfos>;
  currentDoc: (MedicalFileInfos & DocumentInfos) | undefined;
  data: RequestData | undefined;
  list: unknown[];
}

export const GET_DOCUMENTS_RECEIVED = "GET_DOCUMENTS_RECEIVED";
export const GET_CURRENT_DOC = "GET_CURRENT_DOC";
const SET_DOC_AS_READ = "SET_DOC_AS_READ";
export const CLEAR_CURRENT_DOC_REQUEST = "CLEAR_CURRENT_DOC_REQUEST";
export const GET_REQUEST_DATA = "GET_REQUEST_DATA";
export const CLEAR_REQUEST_DATA = "CLEAR_REQUEST_DATA";
export const GET_REQUESTS_LIST = "GET_REQUESTS_LIST";

interface GetDocumentsAction {
  type: typeof GET_DOCUMENTS_RECEIVED;
  payload: [];
}

interface GetCurrentDocAction {
  type: typeof GET_CURRENT_DOC;
  payload: MedicalFileInfos;
}

interface SetDocAsReadAction {
  type: typeof SET_DOC_AS_READ;
  payload: Record<string, unknown>;
}

interface GetRequestData {
  type: typeof GET_REQUEST_DATA;
  payload: RequestData;
}

interface ClearRequestData {
  type: typeof CLEAR_REQUEST_DATA;
}

interface ClearCurrentDocAction {
  type: typeof CLEAR_CURRENT_DOC_REQUEST;
}

interface GetRequestsList {
  type: typeof GET_REQUESTS_LIST;
  payload: [];
}

export type RequestActionTypes =
  | GetDocumentsAction
  | GetCurrentDocAction
  | SetDocAsReadAction
  | ClearCurrentDocAction
  | GetRequestData
  | ClearRequestData
  | GetRequestsList;
