import {
  GET_DOCUMENTS_RECEIVED,
  RequestActionTypes,
  GET_CURRENT_DOC,
  CLEAR_CURRENT_DOC_REQUEST,
  RequestState,
  GET_REQUEST_DATA,
  GET_REQUESTS_LIST,
  CLEAR_REQUEST_DATA,
} from "./types";

const initialState: RequestState = {
  documents: [],
  currentDoc: undefined,
  data: undefined,
  list: undefined,
};

export function requestReducer(
  state = initialState,
  action: RequestActionTypes,
): RequestState {
  switch (action.type) {
    case GET_DOCUMENTS_RECEIVED:
      return {
        ...state,
        documents: action.payload,
      };
    case GET_CURRENT_DOC:
      return {
        ...state,
        currentDoc: action.payload,
      };
    case CLEAR_CURRENT_DOC_REQUEST:
      return {
        ...state,
        currentDoc: undefined,
      };
    case GET_REQUEST_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAR_REQUEST_DATA:
      return {
        ...state,
        data: undefined,
        list: undefined,
        documents: [],
      };
    case GET_REQUESTS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
}
