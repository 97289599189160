import React, { ErrorInfo } from "react";
import Error from "./Error";
import { connect } from "react-redux";
import { postErrorLogs } from "api/logs";

interface Props {
  axiosError: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentStack } = errorInfo;
    const infoArray = componentStack.split("\n");

    const infos = infoArray.reduce((prev, curr, index) => {
      if (!curr) {
        return prev;
      }
      prev[`componentStack_${index}`] = curr;
      return prev;
    }, {} as { [key: string]: string });

    postErrorLogs({
      req_path: window.location.pathname,
      msg: error.message,
      ...infos,
    });
  }

  componentDidUpdate = () => {
    if (this.props.axiosError) {
      if (!this.state.hasError) this.setState({ hasError: true });
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error refresh={() => this.setState({ hasError: false })} />;
    } else {
      return this.props.children;
    }
  }
}

function mapStateToProps(state: { global: { axiosError: string } }) {
  return {
    axiosError: state.global.axiosError,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
