import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showCookiePopin } from "store/global/actions";
import "./Footer.scss";
import Link from "components/Link";
import { Logo, Text } from "@carians/carians-ui";

const Footer = ({ noBorder }: { noBorder?: boolean }) => {
  const { t } = useTranslation("footer");
  const dispatch = useDispatch();
  return (
    <div className={`Footer ${noBorder ? "no-border" : ""}`} id="Footer">
      <div className="Footer-logo-copy">
        <div className="Footer-img">
          <Logo width={84} />
        </div>
        <Text as="div" variant="body-medium" className="copy-right">
          {t("copyRight")}
        </Text>
      </div>
      <div className="Footer-info">
        <div className="info">
          <Text
            as="div"
            variant="body-medium"
            className="link"
            onClick={() => dispatch(showCookiePopin())}
          >
            {t("cookies")}
          </Text>
        </div>
        <div className="info">
          <Text
            variant="body-medium"
            as={Link}
            url={`https://www.${
              window.REACT_APP_ENVIRONMENT_MODE === "test" ? "staging." : ""
            }deuxiemeavis.fr/doc/politique-de-confidentialite.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("infoOne")}
          </Text>
        </div>
        <div className="info">
          <Text
            variant="body-medium"
            as={Link}
            url={`https://www.${
              window.REACT_APP_ENVIRONMENT_MODE === "test" ? "staging." : ""
            }deuxiemeavis.fr/doc/conditions-generales-de-services.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("infoTwo")}
          </Text>
        </div>
        <div className="info">
          <Text
            variant="body-medium"
            as={Link}
            url={`https://www.${
              window.REACT_APP_ENVIRONMENT_MODE === "test" ? "staging." : ""
            }deuxiemeavis.fr/doc/mentions-legales.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("infoThree")}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;
