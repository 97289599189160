import React, { useEffect } from "react";
import { Formik, FormikHelpers, Form, FormikErrors } from "formik";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
/* Components */
import Resum from "../Resum";
import Questions from "./Questions";
import Signature from "../Signature";
import DocumentTitle from "../DocumentTitle";
/* Types */
import { DocumentsState } from "store/documents/types";
/* Style */
import style from "./TemplateOpinionFr.module.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { postOpinion } from "api/documents";
import { setLoading } from "store/documents/actions";
import { TFunction } from "react-i18next";
import { useDocument } from "contexts/DocumentContext";
import Title from "../Title";
import Introduction from "../Introduction";
import QuestionsAsked from "../QuestionsAsked";
import { Box } from "@carians/carians-ui";
import { getTrimValues } from "utils/trimValues";

interface RouteInfo {
  request_id: string;
}

export interface TemplateOpinionFrValues {
  question_1?: string;
  question_2?: string;
  question_3_1?: string;
  question_3_2?: string;
  question_3_3?: string;
  question_4?: string;
  signature?: string;
}

interface IPropsTemplateOpinionFr extends RouteComponentProps<RouteInfo> {
  t: TFunction;
}

const TemplateOpinionFr = ({ match, t }: IPropsTemplateOpinionFr) => {
  const { request_id } = match.params;
  const { dispatch: documentDispatch, state } = useDocument();

  const { first_name, last_name } = useSelector(
    (state: StoreState) => state.session.user,
  );
  const dispatch = useDispatch();

  const initialValues: TemplateOpinionFrValues = {
    question_1: "",
    question_2: "",
    question_3_1: "",
    question_3_2: "",
    question_3_3: "",
    question_4: "",
    signature: (first_name && last_name && first_name + " " + last_name) || "",
  };

  const currentDoc = useSelector(
    (state: { documents: DocumentsState }) => state.documents.currentDoc,
  );

  const opinion = useSelector(
    (state: { documents: DocumentsState }) => state.documents.opinion,
  );

  if (opinion.review) {
    initialValues.question_1 = opinion.review;
  }
  if (opinion.reservation) {
    initialValues.question_2 = opinion.reservation;
  }
  if (opinion.diagnostic) {
    initialValues.question_3_1 = opinion.diagnostic;
  }
  if (opinion.treatment) {
    initialValues.question_3_2 = opinion.treatment;
  }
  if (opinion.consequence) {
    initialValues.question_3_3 = opinion.consequence;
  }
  if (opinion.conclusion) {
    initialValues.question_4 = opinion.conclusion;
  }
  if (opinion.signature) {
    initialValues.signature = opinion.signature;
  }

  const handleSubmit = async (
    values: TemplateOpinionFrValues,
    actions: FormikHelpers<TemplateOpinionFrValues>,
  ) => {
    saveOpinion(values);
    actions.setSubmitting(false);
  };

  const validate = (values: TemplateOpinionFrValues) => {
    const errors: FormikErrors<TemplateOpinionFrValues> = {};

    const trimValues = getTrimValues(values);

    if (trimValues.question_1 === "") {
      errors.question_1 = "form:required";
    }

    if (trimValues.question_3_1 === "") {
      errors.question_3_1 = "form:required";
    }

    if (trimValues.question_4 === "") {
      errors.question_4 = "form:required";
    }

    if (trimValues.signature === "") {
      errors.signature = "form:required";
    }
    if (Object.keys(errors).length === 0) {
      documentDispatch &&
        documentDispatch({ type: "UPDATE_VALID_FORM", payload: true });
    } else {
      documentDispatch &&
        documentDispatch({ type: "UPDATE_VALID_FORM", payload: false });
    }

    return errors;
  };

  useEffect(() => {
    return () => {
      documentDispatch && documentDispatch({ type: "RESET_STATE" });
    };
  }, []);

  const saveOpinion = async (values: {
    question_1?: string;
    question_2?: string;
    question_3_1?: string;
    question_3_2?: string;
    question_3_3?: string;
    question_4?: string;
    signature?: string;
  }) => {
    if (!currentDoc) {
      return;
    }

    let opinionSave: {
      review?: string;
      reservation?: string;
      diagnostic?: string;
      consequence?: string;
      treatment?: string;
      conclusion: string;
      signature: string;
    } = { conclusion: "", signature: "" };

    if (values.question_1) {
      opinionSave = { ...opinionSave, review: values.question_1 };
    }
    if (values.question_2) {
      opinionSave = { ...opinionSave, reservation: values.question_2 };
    }
    if (values.question_3_1) {
      opinionSave = { ...opinionSave, diagnostic: values.question_3_1 };
    }
    if (values.question_3_2) {
      opinionSave = { ...opinionSave, treatment: values.question_3_2 };
    }
    if (values.question_3_3) {
      opinionSave = { ...opinionSave, consequence: values.question_3_3 };
    }
    if (values.question_4) {
      opinionSave = { ...opinionSave, conclusion: values.question_4 };
    }
    if (values.signature) {
      opinionSave = { ...opinionSave, signature: values.signature };
    }
    dispatch(setLoading(true));
    await postOpinion(request_id, opinionSave, currentDoc.id);
    dispatch(setLoading(false));
  };
  if (!currentDoc) {
    return null;
  }

  useEffect(() => {
    validate(initialValues);
  }, [currentDoc, location]);

  return (
    <Box
      textStyle="body"
      className={cn(style.root, {
        [style.evaluationMode]: state?.evaluationMode,
      })}
    >
      {!state?.evaluationMode && <DocumentTitle type="opinion" />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors }) => (
          <Form id="TemplateOpinion-form">
            {!state?.evaluationMode ? (
              <Resum type="opinion" t={t} />
            ) : (
              <>
                <Title type="opinion" t={t} />
                <Introduction type="opinion" t={t} />
                <QuestionsAsked type="opinion" t={t} />
              </>
            )}
            <Questions errors={errors} values={values} t={t} />
            {state?.evaluationMode &&
              t(`template.opinion.proofreading.conclusion`)}
            <Signature
              type="opinion"
              saveOpinion={saveOpinion}
              values={values}
              t={t}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default withRouter(TemplateOpinionFr);
