import { sessionService } from "redux-react-session";

import Octopus from "./Octopus";

export const login = (identifiant: { email: string; password: string }) => {
  return Octopus.request("post", "/ex/accounts/login", {
    ...identifiant,
    useragent: navigator.userAgent,
  })
    .then(async res => {
      if (res.jwt) {
        const user = {
          email: identifiant.email,
          lang: "fr",
          jwt: res.jwt,
        };

        await sessionService.saveUser(user);

        return res;
      } else {
        return res;
      }
    })
    .catch(err => err);
};

export const logout = () => {
  return Octopus.request("post", "/ex/accounts/logout")
    .then(async res => {
      if (res.result && res.result === "ok") {
        await sessionService.deleteSession();
        await sessionService.deleteUser();

        return { success: true };
      } else {
        return res;
      }
    })
    .catch(err => err);
};

export const checkResetPasswordKey = (key: string) => {
  return Octopus.request("get", "/ex/accounts/reset-password", {}, { key })
    .then(res => res)
    .catch(err => err);
};
export const postResetPassword = (email: string) => {
  return Octopus.request(
    "post",
    "/ex/accounts/reset-password",
    { email },
    { account: "expert" },
  )
    .then(res => res)
    .catch(err => err);
};

export const putResetPassword = (password: string, activation_key: string) => {
  return Octopus.request("put", "/ex/accounts/reset-password", {
    password,
    activation_key,
  })
    .then(res => res)
    .catch(err => err);
};

export const patchAccountsExpert = (data: Record<string, unknown>) => {
  return Octopus.request("patch", "/ex/accounts/expert", {
    ...data,
  })
    .then(res => res)
    .catch(err => err);
};

export const getAccountsExpertConnexionInfo = () => {
  return Octopus.request("get", "/ex/accounts/expert/connexion-info")
    .then(async res => {
      try {
        let user = await sessionService.loadUser();

        user = { ...user, ...res.result };
        sessionService.saveUser(user);
        return user;
      } catch (e) {
        console.log("sessionService.loadUser error =>", e);
      }
    })
    .catch(err => err);
};

export const getAccountsExpert = () => {
  return Octopus.request("get", "/ex/accounts/expert")
    .then(async res => {
      try {
        let user = await sessionService.loadUser();

        user = { ...user, ...res.result };
        sessionService.saveUser(user);
        return user;
      } catch (e) {
        console.log("sessionService.loadUser error =>", e);
      }
    })
    .catch(err => err);
};
export const getAccountsExpertActivity = () => {
  return Octopus.request("get", "/ex/accounts/expert/activity")
    .then(async res => res)
    .catch(err => err);
};

export const putAccountsPassword = (
  old_password: string,
  new_password: string,
) => {
  return Octopus.request("put", "/ex/accounts/password", {
    old_password,
    new_password,
  })
    .then(res => res)
    .catch(err => err);
};

export const initPassword = (password: string) => {
  return Octopus.request("post", "/ex/accounts/init-password", { password });
};

export const getAccountsOtp = async (
  type: string,
): Promise<{
  result: {
    message: string;
  };
}> => {
  return Octopus.request("get", "/ex/accounts/otp", {}, { type });
};

export const postAccountsOtp = (otp: string) => {
  return Octopus.request("post", "/ex/accounts/otp", { otp })
    .then(async res => {
      if (res.result && res.result.ok === "1") {
        try {
          const session = await sessionService.loadSession();
          // Existing session (case of expired OTP but valid session)
          if (session && session.jwt) {
            sessionService.saveSession({ ...session, OTP: otp });
          }

          return res;
        } catch (e) {
          // No session found -> create one
          try {
            const user = await sessionService.loadUser();
            sessionService.saveSession({ OTP: otp, jwt: user.jwt });
            sessionService.saveUser(user);

            return res;
          } catch (error) {
            console.log("sessionService.loadUser error =>", error);
            return { success: false, reason: e };
          }
        }
      }
      return res;
    })
    .catch(() => {
      return {
        result: {
          ok: "0",
          fail_otp_number: "5",
        },
      };
    });
};
