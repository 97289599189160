import { QuestionnaireTemplate } from "./../api/request/index";
import { Questionnaire } from "api/request";
import EXIF from "exif-js";
import { TFunction } from "react-i18next";

export const getUrlParams = (searchUrl: string, keys: Array<string>) => {
  const value: Array<string> = [];
  const search = searchUrl.replace("?", "");
  const params = search.split("&");

  params.forEach((param: string) => {
    const name = param.split("=")[0];
    keys.forEach(key => {
      if (name === key) {
        value.push(param.split("=")[1]);
      }
    });
  });

  return value;
};

export const displayPhone = (phone_code: string, telephone: string) => {
  let formatPhone = "";

  if (phone_code && telephone) {
    formatPhone = phone_code;

    if (telephone[0] === "0") {
      formatPhone += "(0)";
      telephone = telephone.substr(1);
    } else {
      formatPhone += "(0)";
    }

    formatPhone += telephone[0];
    telephone = telephone.substr(1);
    const telephoneNumber = telephone.split("");

    telephoneNumber.forEach((number: string, i: number) => {
      if (!(i % 2)) {
        formatPhone += " " + number;
      } else {
        formatPhone += number;
      }
    });

    formatPhone = formatPhone.slice(0, 3) + " " + formatPhone.slice(3);

    return formatPhone;
  }

  return "-";
};

export const getTextWidth = (text: string, font = "500 16px Public Sans") => {
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    if (!("remove" in canvas)) {
      Element.prototype.remove = function () {
        if (canvas.parentNode) {
          canvas.removeChild(this);
        }
      };
    }
    canvas.remove();
    return metrics.width;
  }
  return text.length;
};

export const getMax = (array: { value: string; label: string }[]) => {
  const max = Math.max(...array.map(el => el.label.toString().length));

  const text = array.filter(item => {
    return item.label.toString().length === max;
  });

  if (text[0]) {
    return text[0].label;
  }

  return "";
};

export const checkPassword = (password: string) => {
  const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/g;

  if (!password.match(passwordValidationRegex)) {
    return false;
  }

  return true;
};

export const getExtension = (filename: string) =>
  filename.split(".").pop()?.toLowerCase() || "";

const getBlobData = (doc_content: string) => {
  const bstr = atob(doc_content);
  let n = doc_content.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return [u8arr];
};

export const base64ToImg = (document: {
  content: string;
  "content-type": string;
}) => {
  const mimeType = document["content-type"].split(";")[0];
  const blobData = getBlobData(document.content);
  return new Blob(blobData, {
    type: mimeType,
  });
};

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        let base64;
        if (typeof reader.result === "string") {
          base64 = reader.result.split(",")[1];
        } else {
          base64 = ab2str(reader.result);
          base64 = base64.split(",")[1];
        }

        resolve(base64);
      }
    };
    reader.onerror = error => reject(error);
  });

function ab2str(buf: ArrayBuffer) {
  let str = "";
  new Uint8Array(buf).forEach((byte: number) => {
    str += String.fromCharCode(byte);
  });
  return str;
}

// Convert Data URI to a blob
export function dataURIToBlob(dataURI: string) {
  const binStr = atob(dataURI.split(",")[1]),
    len = binStr.length,
    arr = new Uint8Array(len),
    mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new Blob([arr], {
    type: mimeString,
  });
}

export function randomId() {
  return Math.random().toString(36).substr(2, 9);
}

export function downloadFile(
  content: string,
  type: string,
  file_name: string,
  unique?: boolean,
) {
  const uri = `data:${type};base64,` + content;
  const blob = dataURIToBlob(uri);

  const extention = type.split("/")[1];
  const navigator = window.navigator as
    | {
        msSaveOrOpenBlob?: (blob: Blob, defaultName: string) => void;
      }
    | undefined;

  if (navigator && navigator.msSaveOrOpenBlob) {
    if (type.includes("octet-stream")) {
      // Unique files with an octet-stream extension are videos
      if (unique) {
        navigator.msSaveOrOpenBlob(blob, `${file_name}.mp4`);
      } else if (file_name.split(".").length > 1) {
        navigator.msSaveOrOpenBlob(blob, `${file_name}`);
      } else {
        navigator.msSaveOrOpenBlob(blob, `${file_name}.zip`);
      }
    } else {
      if (file_name.split(".").length > 1) {
        window.navigator.msSaveOrOpenBlob(blob, `${file_name}`);
      } else {
        window.navigator.msSaveOrOpenBlob(blob, `${file_name}.${extention}`);
      }
    }
  } else {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.rel = "noopener";
    if (extention.includes("octet-stream") || extention.includes("quicktime")) {
      // Unique files with an octet-stream extension are videos
      if (unique) {
        link.setAttribute("download", `${file_name}.mp4`);
      } else if (file_name.split(".").length > 1) {
        link.setAttribute("download", `${file_name}`);
      } else {
        link.setAttribute("download", `${file_name}.zip`);
      }
    } else {
      if (file_name.split(".").length > 1) {
        link.setAttribute("download", `${file_name}`);
      } else {
        link.setAttribute("download", `${file_name}.${extention}`);
      }
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

//Count how many numbers of lines with need in a text area
export function getNumberOflinesInTextArea(str: string, id: string): number {
  const el = document.getElementById(id) as HTMLTextAreaElement;
  if (!el) return 1;
  const stringLines = str.split(/\r\n|\r|\n/);
  let numberOfLines = 0;

  stringLines.forEach(i => {
    const arr = getTextWidth(i);
    numberOfLines += Math.ceil(arr / el.offsetWidth);
  });

  if ((str[str.length - 1] === "\n" && str !== "\n") || numberOfLines === 0)
    numberOfLines++;
  return numberOfLines;
}

export const getSignature = async (signature: string) => {
  const newDiv = document.createElement("div");
  newDiv.setAttribute("id", "imgSignature");

  newDiv.innerText = signature;
  document.body.appendChild(newDiv);

  const canvas = document.createElement("canvas");

  canvas.style.height = "54px";
  const ctx = canvas.getContext("2d");

  canvas.width = newDiv.clientWidth;
  canvas.height = 48;

  if (ctx) {
    ctx.font = "24px adobe-handwriting-ernie";
    ctx.fillStyle = "#0d3a4a";
    ctx.fillText(signature, 0, 24);

    const imgSignatureData = canvas.toDataURL("image/png");

    newDiv && document.body.removeChild(newDiv);

    return {
      data: imgSignatureData,
      height: newDiv.clientHeight,
      width: newDiv.clientWidth,
    };
  }

  return {
    data: "",
    height: 0,
    width: 0,
  };
};

export function calculateAge(birthdate: string) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function rotateImage(
  e: React.SyntheticEvent<HTMLImageElement, Event>,
  classContainer: string,
  os?: boolean,
) {
  EXIF.getData(e.currentTarget, () => {
    const orientation = EXIF.getTag(e.currentTarget, "Orientation");

    if (orientation) {
      if (orientation === 6 && !os) {
        e.currentTarget.style.transform = "rotate(90deg)";
      } else if (orientation === 6 && os) {
        e.currentTarget.style.transform = "rotate(0deg)";
      }

      const container = document.getElementsByClassName(classContainer)[0];

      if (container) {
        const height = container.clientHeight;
        e.currentTarget.style.maxWidth = height + "px";
      }
      e.currentTarget.style.visibility = "visible";
    } else {
      e.currentTarget.style.visibility = "visible";
    }
  });
}

export function sortQuestions(
  questions: Record<string, Questionnaire>,
): Questionnaire[] {
  const questionsArr: Questionnaire[] = [];
  Object.keys(questions).forEach(key => {
    questionsArr.push({ ...questions[key], id: key });
  });
  questionsArr.sort((a, b) => {
    return a.position_number - b.position_number;
  });
  return questionsArr;
}

export function generateQuestionnaire(
  t: TFunction,
  questionnaire: Record<string, Questionnaire>,
  resultTemplate: QuestionnaireTemplate = { question: [], response: [] },
) {
  const keys = Object.keys(questionnaire);
  if (resultTemplate) {
    keys.forEach((key: string) => {
      if (!questionnaire[key].response) {
        questionnaire[key].response = t("request:answerEmpty");
      }

      const isNumber = parseInt(questionnaire[key].response);

      if (isNumber || isNumber === 0) {
        const index = resultTemplate.question.findIndex(
          question => question.id === key,
        );

        if (index > -1) {
          const { type, range_end, range_start } =
            resultTemplate.question[index];
          if (type === "slider") {
            questionnaire[key].range_end = range_end;
            questionnaire[key].range_start = range_start;
          }
        }
      }
    });
  }

  if (resultTemplate && resultTemplate.question) {
    const optionalQuestions: QuestionnaireTemplate["question"] = [];

    resultTemplate.question.forEach((question, id: number) => {
      if (question.required === "0" && question.level === "1") {
        optionalQuestions.push({
          ...question,
          position_number: question.position_number ?? id + 1,
        });
      }
    });

    optionalQuestions.forEach(question => {
      const index = keys.findIndex(key => key === question.id);

      if (index === -1) {
        const newQuestion = {
          parent_response_id: "",
          position_number: question.position_number,
          question: question.question,
          response: t("request:answerEmpty"),
          picture: question.picture,
        };

        questionnaire[question.id] = newQuestion;
      }
    });
  }

  return questionnaire;
}

export const validateURL = (path: string) => {
  const parsed = new URL(path, window.location.origin);
  return ["https:", "http:"].includes(parsed.protocol) ? parsed : null;
};

export const mapSet = <T extends unknown, K extends keyof T>(
  maplike: T,
  key: K,
  value: unknown,
) => {
  Object.defineProperty(maplike, key, {
    value: value,
    writable: true,
    enumerable: true,
    configurable: true,
  });
  return value;
};

export const mapGet = <T extends unknown, K extends keyof T>(
  maplike: T,
  key: K,
): T[K] | undefined => {
  // nosemgrep-explanation: check if key is present in the array
  if (Object.prototype.hasOwnProperty.call(maplike, key)) return maplike[key]; // nosemgrep
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectsIsEquals = (obj1: any, obj2: any) => {
  // Vérifier si les types des objets sont identiques
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  // Vérifier si les deux objets ont le même nombre de propriétés
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Parcourir les propriétés de obj1 et comparer avec celles de obj2
  for (const key of keys1) {
    // Vérifier si les clés existent dans obj2
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // Récursion : comparer les valeurs si les deux propriétés sont des objets
    if (!objectsIsEquals(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
