import {
  AppState,
  GET_MESSAGES,
  CLEAR_MESSAGES,
  CLEAR_FEEDBACK,
  GET_FEEDBACK,
} from "./types";

export function getMessages(messages: AppState["messages"]) {
  return {
    type: GET_MESSAGES,
    payload: messages,
  };
}

export function getFeedback(feedback: AppState["feedback"]) {
  return {
    type: GET_FEEDBACK,
    payload: feedback,
  };
}

export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  };
}

export function clearFeedback() {
  return {
    type: CLEAR_FEEDBACK,
  };
}
