export interface GlobalState {
  screenWidth: string;
  screenHeight: string;
  disableGetOtp: boolean;
  otpInit: boolean;
  otpTimer: number;
  otpSend: boolean;
  otpGetError: boolean;
  menuIsPush: boolean;
  status: string;
  applicant_id: string;
  gender: unknown;
  tempPassword: string | undefined;
  cookiePopinIsOpen: boolean;
  axiosError: string | undefined;
}

export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";
export const GET_OTP = "GET_OTP";
export const GET_OTP_ERROR = "GET_OTP_ERROR";
export const DECREMENT_OTP_TIMER = "DECREMENT_OTP_TIMER";
export const ENABLE_GET_OTP = "ENABLE_GET_OTP";
export const PUSH_MENU = "PUSH_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const STORE_STATUS_CODE = "STORE_STATUS_CODE";
export const GET_GENDER = "GET_GENDER";
export const SHOW_COOKIE_POPIN = "SHOW_COOKIE_POPIN";
export const HIDE_COOKIE_POPIN = "HIDE_COOKIE_POPIN";
export const AXIOS_ERROR = "AXIOS_ERROR";

interface SetScreenSizeAction {
  type: typeof SET_SCREEN_SIZE;
  payload: [string, string];
}

interface GetOtpAction {
  type: typeof GET_OTP;
  payload: boolean;
}

interface GetGenderAction {
  type: typeof GET_GENDER;
  payload: [];
}

interface GetOtpErrorAction {
  type: typeof GET_OTP_ERROR;
}

interface decrementOtpTimerAction {
  type: typeof DECREMENT_OTP_TIMER;
}

interface enableGetOtpAction {
  type: typeof ENABLE_GET_OTP;
}

interface pushMenuAction {
  type: typeof PUSH_MENU;
}

interface closeMenuAction {
  type: typeof CLOSE_MENU;
}

interface storeStatusAction {
  type: typeof STORE_STATUS_CODE;
  payload: {
    status_code: string;
    applicant_id: string;
  };
}
interface showCookieAction {
  type: typeof SHOW_COOKIE_POPIN;
  payload: undefined;
}
interface hideCookieAction {
  type: typeof HIDE_COOKIE_POPIN;
  payload: undefined;
}

interface AxiosError {
  type: typeof AXIOS_ERROR;
  payload: string;
}

export type GlobalActionTypes =
  | SetScreenSizeAction
  | GetOtpAction
  | GetOtpErrorAction
  | decrementOtpTimerAction
  | enableGetOtpAction
  | pushMenuAction
  | closeMenuAction
  | storeStatusAction
  | GetGenderAction
  | showCookieAction
  | hideCookieAction
  | AxiosError;
