import React from "react";

import "../style.scss";
import Question from "../Question";
import { TemplateOpinionEnValues } from "./TemplateOpinionEn";
import { FormikErrors } from "formik";
import { TFunction } from "react-i18next";
import { useDocument } from "contexts/DocumentContext";
import { ListBulletIcon } from "@carians/carians-ui";

interface IPropsQuestions {
  values: TemplateOpinionEnValues;
  t: TFunction;
  errors: FormikErrors<TemplateOpinionEnValues>;
}

const Questions = ({ values, t, errors }: IPropsQuestions) => {
  const { state } = useDocument();
  const data = [
    {
      question: !state?.evaluationMode
        ? t("template.inter.title")
        : t("template.inter.subTitle"),
      answer: !state?.evaluationMode ? t("template.inter.subTitle") : "",
      icon: <ListBulletIcon />,
      required: true,
    },
  ];

  return (
    <div className="TemplateOpinion-questions">
      <div className="questions">
        {data.map((q, id) => (
          <Question
            key={id}
            id={id + 1}
            values={values}
            t={t}
            autoFocus={id === 0}
            errors={errors}
            {...q}
          />
        ))}
      </div>
    </div>
  );
};

export default Questions;
