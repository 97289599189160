import React from "react";
import "../style.scss";
import Question from "../Question";
import { useSelector } from "react-redux";
import { DocumentsState } from "store/documents/types";
import { TFunction } from "react-i18next";
import { TemplateOpinionFrValues } from "./TemplateOpinionFr";
import { FormikErrors } from "formik";
import { useDocument } from "contexts/DocumentContext";
import {
  ListBulletIcon,
  SearchIcon,
  DocumentTextIcon,
  InterrogationIcon,
} from "@carians/carians-ui";

interface IPropsQuestions {
  values: TemplateOpinionFrValues;
  t: TFunction;
  errors: FormikErrors<TemplateOpinionFrValues>;
}

const Questions: React.FC<IPropsQuestions> = ({ values, t, errors }) => {
  const currentDoc = useSelector(
    (state: { documents: DocumentsState }) => state.documents.currentDoc,
  );
  const { state } = useDocument();

  const data = [
    {
      question: !state?.evaluationMode
        ? t("template.opinion.questions.question_1")
        : t("template.opinion.questions.question_eval_1"),
      answer: "",
      placeholder: t("template.opinion.questions.placeholder_1"),
      icon: <ListBulletIcon />,
      required: true,
    },
    {
      question: !state?.evaluationMode
        ? t("template.opinion.questions.question_2")
        : t("template.opinion.questions.question_eval_2"),
      answer: t(
        !state?.evaluationMode
          ? "template.opinion.questions.info_question_2"
          : "",
      ),
      placeholder: t("template.opinion.questions.placeholder_2"),
      icon: <InterrogationIcon />,
    },
    {
      question: !state?.evaluationMode
        ? t("template.opinion.questions.question_3")
        : t("template.opinion.questions.question_eval_3"),
      answer: t("template.opinion.questions.info_question_3"),
      placeholder: t("template.opinion.questions.placeholder_3"),
      icon: <SearchIcon />,
      answers: [
        {
          title: !state?.evaluationMode
            ? t("template.opinion.questions.sub_question_3.sub_1")
            : t("template.opinion.questions.sub_question_3.sub_eval_1"),
          placeholder: t(
            "template.opinion.questions.sub_question_3.placeholder_sub_1",
          ),
          required: true,
        },
        {
          title: !state?.evaluationMode
            ? t("template.opinion.questions.sub_question_3.sub_2")
            : t("template.opinion.questions.sub_question_3.sub_eval_2"),
          placeholder: t(
            "template.opinion.questions.sub_question_3.placeholder_sub_2",
          ),
        },
        {
          title: !state?.evaluationMode
            ? t("template.opinion.questions.sub_question_3.sub_3")
            : t("template.opinion.questions.sub_question_3.sub_eval_3"),
          placeholder: t(
            "template.opinion.questions.sub_question_3.placeholder_sub_3",
          ),
        },
      ],
    },
    {
      question: !state?.evaluationMode
        ? t("template.opinion.questions.question_4")
        : t("template.opinion.questions.question_eval_4"),
      answer: !state?.evaluationMode
        ? t("template.opinion.questions.info_question_4")
        : "",
      placeholder: t("template.opinion.questions.placeholder_4"),
      icon: <DocumentTextIcon />,
      required: true,
    },
  ];

  return (
    <div className="TemplateOpinion-questions">
      <div className="questions">
        {data.map((q, id) => (
          <Question
            key={`${currentDoc?.id}_${id}`}
            id={id + 1}
            values={values}
            t={t}
            autoFocus={id === 0}
            errors={errors}
            {...q}
          />
        ))}
      </div>
    </div>
  );
};

export default Questions;
