import Octopus from "./Octopus";

export const postHotlineCallback = (
  telephone: string,
  availability: string,
) => {
  return Octopus.request("post", "/ex/hotline/callback", {
    email: window.REACT_APP_COORDINATOR_MAIL,
    telephone,
    now: "0",
    availability,
  })
    .then(res => res)
    .catch(err => err);
};

export const postHotlineNoAccount = (
  telephone: string,
  availability: string,
) => {
  return Octopus.request("post", "/ex/hotline/callback/no-account", {
    email: window.REACT_APP_COORDINATOR_MAIL,
    telephone,
    now: "0",
    availability,
  });
};
export interface FAQPage {
  answer: string;
  id: string;
  question: string;
}

export const getFaqPage = (code: string): Promise<{ result: FAQPage[] }> => {
  return Octopus.request("get", "/na/faq/page", undefined, { code });
};
