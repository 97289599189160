import Octopus from "../Octopus";

interface PostLogsParams {
  request_id?: string;
  type: string;
  req_path: string;
  msg: string;
}

type UnknownData = { [key: string]: unknown };

interface PostLogsErrorParams extends UnknownData {
  req_path: string;
  msg: string;
}

export async function postLogs({ ...props }: PostLogsParams) {
  return await Octopus.request("post", `/ex/logs/debug`, {
    ...props,
    level: "ERROR",
    source: "expert",
  });
}

export async function postErrorLogs({ ...props }: PostLogsErrorParams) {
  return await Octopus.request("post", `/ex/logs/debug`, {
    ...props,
    level: "ERROR",
    source: "expert",
  });
}
