import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import { globalReducer } from "./global/reducers";
import { documentsReducer } from "./documents/reducers";
import { requestReducer } from "./request/reducers";
import { appReducer } from "./app/reducers";

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    global: globalReducer,
    documents: documentsReducer,
    request: requestReducer,
    app: appReducer,
  });

export default createRootReducer;
