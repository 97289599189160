import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en";
import fr from "./fr";
import { format } from "date-fns";

const resources = {
  en: en,
  fr: fr,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "fr",
    resources,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss,
      format: function (value, formatting) {
        if (value instanceof Date && value.getTime() && formatting)
          return format(value, formatting);
        return value.toString();
      },
    },
  });

export default i18n;
