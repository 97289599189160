import Octopus from "../Octopus";
import {
  DocumentInfosResult,
  CreateDocumentResult,
  PutDocumentResult,
} from "./documents-types";
import { postLogs } from "api/logs";
import { CancelToken } from "axios";

export const createDocument = async (
  document_type_id: string,
  type: string,
  request_id: string,
  lang: string,
  file_content?: string,
  file_name?: string,
): Promise<CreateDocumentResult> => {
  let payload: {
    type: string;
    lang: string;
    draft: string;
    document_type_id: string;
    file_name: string;
    source_type: string;
    name: string;
    file_content?: string;
  } = {
    type,
    lang,
    draft: "1",
    document_type_id,
    file_name: type + ".pdf",
    source_type: "template",
    name: file_name || "Deuxième avis 1",
  };

  if (file_content) {
    payload = {
      ...payload,
      file_content,
      file_name: file_name || payload.file_name,
      source_type: "upload",
    };
  }

  const res: CreateDocumentResult = await Octopus.request(
    "post",
    "/ex/requests/document",
    payload,
    {
      request_id,
    },
  );
  return res;
};

export const getListDocuments = async (
  request_id: string,
): Promise<DocumentInfosResult> => {
  try {
    const res: DocumentInfosResult = await Octopus.request(
      "get",
      "/ex/requests/document-sent",
      undefined,
      {
        request_id,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteDocument = (request_id: string, document_id: number) => {
  return Octopus.request("delete", "/ex/requests/document", undefined, {
    request_id,
    document_id,
  })
    .then(res => res)
    .catch(err => err);
};
export interface RequestsFile {
  "content-type": string;
  content: string;
}

export const getFile = async (
  request_id: string,
  path: string,
  file_number = 1,
  cancelToken?: CancelToken,
): Promise<{ result: RequestsFile } | RequestsFile> => {
  const res = await Octopus.request(
    "get",
    "/ex/requests/file",
    undefined,
    {
      request_id,
      path,
    },
    file_number > 1 ? "arraybuffer" : "json",
    cancelToken,
  );

  if (res.data && res.headers) {
    const buffer = Buffer.from(res.data, "binary").toString("base64");
    return {
      content: buffer,
      "content-type": res.headers["content-type"],
    };
  }
  return res;
};

export const getFilesName = (request_id: string, file_id: string) => {
  return Octopus.request("get", "/ex/requests/files/medical-file", undefined, {
    request_id,
    file_id,
  })
    .then(res => res)
    .catch(err => err);
};

export const deleteQuotation = (request_id: string, quotation_id: string) => {
  return Octopus.request("delete", "/ex/requests/quotation", undefined, {
    request_id,
    quotation_id,
  })
    .then(res => res)
    .catch(err => err);
};

export const putDocument = async (
  request_id: string,
  document_id: number,
  name: string,
  file_name?: string,
  file_content?: string,
): Promise<PutDocumentResult | undefined> => {
  let payload: {
    name: string;
    file_name?: string;
    file_content?: string;
    source_type?: string;
  } = { name };

  if (file_name) {
    payload = { ...payload, file_name, file_content };
  }

  try {
    const res: PutDocumentResult = await Octopus.request(
      "put",
      "/ex/requests/document",
      payload,
      {
        request_id,
        document_id,
      },
    );
    return res;
  } catch (error) {
    postLogs({
      type: "old-logs",
      msg: JSON.stringify(error),
      req_path: window.location.pathname,
    });
    return;
  }
};

export const transmitDocument = async (
  request_id: string,
  document_id: number,
  recipient_id: string,
): Promise<boolean> => {
  try {
    await Octopus.request(
      "patch",
      "/ex/requests/document",
      {
        draft: "0",
        recipient_table: "applicant",
        recipient_id,
      },
      {
        request_id,
        document_id,
      },
    );
    return true;
  } catch (error) {
    postLogs({
      type: "old-logs",
      msg: JSON.stringify(error),
      req_path: window.location.pathname,
    });
    return false;
  }
};

export const postOpinion = (
  request_id: string,
  opinion: {
    review?: string;
    reservation?: string;
    diagnostic?: string;
    consequence?: string;
    treatment?: string;
    conclusion?: string;
    signature: string;
  },
  document_id?: number,
) => {
  return Octopus.request(
    "post",
    "/ex/requests/medical-opinion",
    {
      ...opinion,
    },
    {
      request_id,
      document_id,
    },
  )
    .then(res => res)
    .catch(err => {
      postLogs({
        type: "old-logs",
        msg: JSON.stringify(err),
        req_path: window.location.pathname,
      });
      return err;
    });
};
export interface MedicalOpinion {
  request_id: string;
  review?: string;
  reservation?: string;
  diagnostic?: string;
  consequence?: string;
  treatment?: string;
  conclusion: string;
  signature: string;
}

export const fetchOpinion = async (
  request_id: string,
  document_id: number,
): Promise<MedicalOpinion> => {
  try {
    const response = await Octopus.request(
      "get",
      "/ex/requests/medical-opinion",
      undefined,
      {
        request_id,
        document_id,
      },
    );
    return response && response.result ? response.result : {};
  } catch (err) {
    postLogs({
      type: "old-logs",
      msg: JSON.stringify(err),
      req_path: window.location.pathname,
    });
    throw err;
  }
};
export interface QuotationLine {
  element_type: string;
  line_id: string;
  name: string;
  order_number: string;
  quantity: string;
  quotation_id: string;
  quotation_name: string;
  signature: string;
  unit_price: string;
}

export const getQuotationLines = async (
  request_id: string,
  quotation_id: string,
): Promise<QuotationLine[]> => {
  const response = await Octopus.request(
    "get",
    "/ex/requests/quotation-line",
    undefined,
    {
      request_id,
      quotation_id,
    },
  );
  return response?.result || [];
};

export const patchQuotationLine = (
  request_id: string,
  quotation_id: string,
  lineData: Partial<QuotationLine>,
) => {
  return Octopus.request(
    "patch",
    "/ex/requests/quotation-line",
    {
      ...lineData,
    },
    {
      request_id,
      quotation_id,
    },
  )
    .then(response => response.result)
    .catch(err => err);
};

export const putQuotationLine = (
  request_id: string,
  quotation_id: string,
  line_id: string,
  lineData: {
    name: string;
    unit_price?: number;
    quantity?: number;
    order_number?: number;
  },
) => {
  return Octopus.request(
    "put",
    "/ex/requests/quotation-line",
    {
      ...lineData,
    },
    {
      request_id,
      quotation_id,
      line_id,
    },
  )
    .then(response => response.result)
    .catch(err => err);
};

export const deleteQuotationLine = (
  request_id: string,
  quotation_id: string,
  line_id: string,
) => {
  return Octopus.request("delete", "/ex/requests/quotation-line", undefined, {
    request_id,
    quotation_id,
    line_id,
  })
    .then(response => response.result)
    .catch(err => err);
};

export const orderQuotationLines = (
  request_id: string,
  quotation_id: string,
  ids: number[],
) => {
  return Octopus.request(
    "patch",
    "/ex/requests/quotations/order?",
    { ids },
    {
      request_id,
      quotation_id,
    },
  )
    .then(response => response.result)
    .catch(err => err);
};

export interface IQuotationSuggest {
  name: string;
  quantity: string;
  unit_price: string;
}

export const suggestQuotationLine = (
  type: "line" | "title",
): Promise<{ result: IQuotationSuggest[] }> => {
  return Octopus.request("get", "/ex/requests/quotations/suggest", undefined, {
    type,
  });
};
