import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import { createBrowserHistory } from "history";

export type AppState = ReturnType<typeof createRootReducer>;

export const history = createBrowserHistory();

export default function configureStore() {
  const middlewares = [thunkMiddleware, routerMiddleware(history)];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(middleWareEnhancer),
  );

  return store;
}
